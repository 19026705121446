<div class="opening-hours flex flex-row">

    <div class="">
        <div class="">
            <span>{{ "fields.openingHours.label" | translate }}</span>
        </div>
        <span>
            <mat-checkbox [formControl]="this.nonstopCntr">{{ "openingHours.nonstop" | translate }}</mat-checkbox>
        </span>
    </div>

    <div class="opening-hours">
        <div *ngFor="let day of week" class="opening-hours__row">

            <div class="opening-hours__header">
                <mat-slide-toggle [formControl]="day.enabled"></mat-slide-toggle>
                <span class="opening-hours__label ml-3">
                    {{ "weekdays."+day.weekDay.toLocaleLowerCase() | translate }}
                </span>
                <div *ngIf="!day.enabled.value" class="ml-4 p-1 bg-red text-white rounded-lg">
                    {{ "openingHours.closed" | translate }}
                </div>
            </div>

            <div class="opening-hours__body">
                <div class="opening-hours__segments">
                    <div *ngFor="let segment of day.segments">

                        <div *ngIf="day.enabled.value" class="opening-hours__time">
                            <div class="floating-label-input">
                                <input matInput type="text" [formControl]="segment.timeFrom" [ngClass]="{ 
                                    'focused' : !!segment.timeFrom?.value
                                }">
                                <label>
                                    {{ "openingHours.openFrom" | translate }}
                                </label>
                            </div>

                            <div class="floating-label-input">
                                <input type="text" [formControl]="segment.timeTill" [ngClass]="{ 
                                    'focused' : !!segment.timeTill?.value
                                }">
                                <label>
                                    {{ "openingHours.openTill" | translate }}
                                </label>
                            </div>
                            
                            <button mat-icon-button aria-label="Clear" *ngIf="day.enabled.enabled"
                                (click)="removeSegment(day, segment)">
                                <mat-icon svgIcon="close"></mat-icon>
                            </button>

                            <button mat-icon-button aria-label="Add"
                                *ngIf="day.enabled.enabled && day.segments.length < 2" (click)="addSegment(day)">
                                <mat-icon svgIcon="add"></mat-icon>
                            </button>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>

</div>