<div class="floating-label-input" [ngClass]="{ 'invalid' : !this.isFieldValid() }">
    <input 
        [formControl]="this.profileCtrl"
        [ngClass]="{ 
            'focused' : !!this.profileCtrl?.value, 
            'invalid' : !this.isFieldValid() 
        }">
    <label>
        {{ this.label }}
    </label>
    <button
        (click)="clearValue()" 
        *ngIf="!!this.profileCtrl?.value">
        <div class="cross"></div>
    </button>
</div>
<div class="error-message" *ngIf="!this.isFieldValid()">
    <div class="warning-icon"></div> {{ this.getError() | translate }}
</div>