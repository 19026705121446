import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export type Locale = "be_" | "nl_";

@Injectable({
  providedIn: 'root',
})
export class MultiLocaleTranslateService extends TranslateService {
  public locale: Locale;
  private storageKeyBE = "MYFCRMEDIACOM_LANG";
  private storageKeyNL = "MIJNYOUVIANL_LANG";

  isLocaleBE() {  
    if(this.locale = "be_")
      return true
    return false
  }

  setLocale(locale: Locale) {
    this.locale = locale
  }

  useLang(lang: string) {
    this.use(this.locale + lang);
    this.storeLastLang(lang);
  }

  setLocaleByDomain(_locale: Locale, lang?: string) {
    this.locale = _locale;
    if(_locale == "be_"){
      const beLastLang = localStorage.getItem(this.storageKeyBE);
      if(beLastLang && beLastLang != "")
        this.useLang(beLastLang)
      else
        this.useLang("nl")
    }
    else {
      const nlLastLang = localStorage.getItem(this.storageKeyNL);
      if(nlLastLang && nlLastLang != "")
        this.useLang(nlLastLang)
      else
        this.useLang("nl")
    }
  }

  storeLastLang(lang: string) {
    //console.log("store last lang", lang)
    if(this.locale == "be_")
      localStorage.setItem(this.storageKeyBE, lang.replace("be_", ""))
    else
      localStorage.setItem(this.storageKeyNL, lang.replace("nl_", ""))
  }
}
