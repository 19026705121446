import { HeadingModel } from "./heading.model";

export class FormDataModel {


    public enterpriseNumber: string = ""; // BE only
    public kvkNumber: string = ""; // NL only
    public organisationName: string = "";
    public mainLanguage: string = "";
    public heading: HeadingModel | null = null;
    public headingId: string | null = null;
    public listingId: string = "";

    public firstName: string | null = null;
    public lastName: string | null = null;


    // address
    public addressStreet: string | null = null;
    public addressStreetNumber: string | null = null;
    public addressStreetDoor: string | null = null;
    public addressCity: string | null = null;
    public addressDistrict: string | null = null;
    public addressPostalCode: string | null = null;
    public addressCountry: string | null = null;


    // contacts
    public email: string | null = null;
    public fixedPhoneNumber: string | null = null;
    public mobilePhoneNumber: string | null = null;
    public website: string | null = null;
    public webshop: string | null = null;
    public facebook: string | null = null;
    public instagram: string | null = null;
    public linkedIn: string | null = null;

    // opening hours
    public openingHours: Array<OpeningHoursItem> | null = new Array<OpeningHoursItem>();
    public openingHoursAdditionalInfo: string | null = null;

    // consens
    public consentPrivacyPolicy: boolean = false;
    public consentTermsAndConditions: boolean = false;

    // profile completeness

    public profileCompletenessPercentage: number;
    public profileCompleteness: ProfileCompletenessItem[];

    // meta data
    public brand: string = "FCR Media";
    public leadCampaign: string = "MYB";
    public leadSource: string = "Scale Up";
    public utmTags: Array<UtmTagModel> = [];

    constructor() {
       
    }

}

export class UtmTagModel {
    public code: string = "";
    public value: string = "";
}



export class OpeningHoursItem {
    public weekDay: WeekDayEnum = WeekDayEnum.Monday;
    public fromTime: string = "09:00";
    public tillTime: string = "17:00";
}


export enum WeekDayEnum {
    Monday = "Monday",
    Tuesday = "Tuesday",
    Wednesday = "Wednesday",
    Thursday = "Thursday",
    Friday = "Friday",
    Saturday = "Saturday",
    Sunday = "Sunday",
}

export class ProfileCompletenessItem {
    key: string
    weight: number
    isPassed: boolean
}