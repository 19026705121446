<div class="mb-6">
    <h1 class="text-2xl font-semibold">My statistics</h1>
  </div>
<div class="bg-white rounded shadow">
    <div class="bg-white p-3 flex flex-col md:flex-row items-center gap-4 pb-6">
        <!-- Company Select -->
        <div class="form-group">
          <p for="companySelect" class="form-label mb-3 fw-bold">Company name</p>
          <div class="custom-dropdown">
            <!-- Toggle Button -->
            <button id="companySelect" class="dropdown-toggle flex items-center gap-4 w-96 justify-between" (click)="toggleCompanyDropdown()">
              <div>
                <span class="fw-bold d-block">{{ selectedCompany.name }}</span>
                <span>{{ selectedCompany.address }}</span>
              </div>
              <div class="flex justify-items-center items-center">
                <i class="fa-solid fa-chevron-down text-gray-600"></i>
              </div>
            </button>
      
            <!-- Dropdown Menu -->
            <ul class="dropdown-menu" [class.show]="isCompanyDropdownOpen">
              <li *ngFor="let company of companies" class="dropdown-item" (click)="selectCompany(company)">
                <span class="fw-bold d-block">{{ company.name }}</span>
                <span class="text-muted small">{{ company.address }}</span>
              </li>
            </ul>
          </div>
        </div>
      
        <!-- Period Select -->
        <div class="form-group">
          <p for="periodSelect" class="form-label mb-3 fw-bold">Period</p>
          <div class="custom-dropdown">
            <!-- Toggle Button -->
            <button id="periodSelect" class="dropdown-toggle flex items-center gap-4 w-96 justify-between" (click)="togglePeriodDropdown()">
              <div>
                <span class="fw-bold d-block">{{ selectedPeriod.label }}</span>
              </div>
              <div class="flex justify-items-center items-center">
                <i class="fa-solid fa-chevron-down text-gray-600"></i>
              </div>
            </button>
      
            <!-- Dropdown Menu -->
            <ul class="dropdown-menu" [class.show]="isPeriodDropdownOpen">
              <li *ngFor="let period of periods" class="dropdown-item" (click)="selectPeriod(period)">
                <span class="fw-bold">{{ period.label }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    <div class="bg-analytics p-3 ">
        <!-- Metrics Section -->
        <div class="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
            <!-- Impressions -->
            <div class="bg-white shadow rounded-lg p-4">
                <div class="flex items-center pb-5 mb-5 border-b border-gray-300">
                    <!-- Icon -->
                    <div class="w-10 h-10 flex items-center justify-center rounded-full bg-primary text-white">
                        <div class="w-10 h-10 flex items-center justify-center rounded-full bg-primary text-white">
                            <svg width="25" height="24" class="h-6 w-6" viewBox="0 0 25 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M6.45837 9.77344C6.45837 9.35922 6.80536 9.02344 7.23337 9.02344H17.5667C17.9947 9.02344 18.3417 9.35922 18.3417 9.77344C18.3417 10.1877 17.9947 10.5234 17.5667 10.5234H7.23337C6.80536 10.5234 6.45837 10.1877 6.45837 9.77344Z"
                                    fill="white" />
                                <path
                                    d="M7.23337 12.0234C6.80536 12.0234 6.45837 12.3592 6.45837 12.7734C6.45837 13.1877 6.80536 13.5234 7.23337 13.5234H12.4C12.828 13.5234 13.175 13.1877 13.175 12.7734C13.175 12.3592 12.828 12.0234 12.4 12.0234H7.23337Z"
                                    fill="white" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M2.58337 5.53125C2.58337 4.28861 3.62431 3.28125 4.90837 3.28125H19.8917C21.1757 3.28125 22.2167 4.28861 22.2167 5.53125V16.0796C22.2167 17.3223 21.1757 18.3296 19.8917 18.3296H15.6048L13.0226 21.6956C12.8764 21.8861 12.6457 21.9985 12.4003 21.9985C12.1551 21.9985 11.9243 21.8861 11.7781 21.6956L9.19599 18.3296H4.90837C3.62431 18.3296 2.58337 17.3223 2.58337 16.0796V5.53125ZM4.90837 4.78125C4.48036 4.78125 4.13337 5.11704 4.13337 5.53125V16.0796C4.13337 16.4938 4.48036 16.8296 4.90837 16.8296H9.58586C9.83113 16.8296 10.0619 16.942 10.2081 17.1326L12.4003 19.9903L14.5927 17.1326C14.7389 16.942 14.9696 16.8296 15.2149 16.8296H19.8917C20.3197 16.8296 20.6667 16.4938 20.6667 16.0796V5.53125C20.6667 5.11704 20.3197 4.78125 19.8917 4.78125H4.90837Z"
                                    fill="white" />
                            </svg>

                        </div>

                    </div>
                    <div class="ml-4">
                        <div class="flex items-center">
                            <span class="text-sm font-medium text-gray-700">{{metrics[0].title}}</span>
                            <span class="ml-1 text-gray-400">
                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M10.8 1.66699C6.19763 1.66699 2.46667 5.39795 2.46667 10.0003C2.46667 14.6027 6.19763 18.3337 10.8 18.3337C15.4023 18.3337 19.1333 14.6027 19.1333 10.0003C19.1333 5.39795 15.4023 1.66699 10.8 1.66699ZM3.71667 10.0003C3.71667 6.08831 6.88799 2.91699 10.8 2.91699C14.712 2.91699 17.8833 6.08831 17.8833 10.0003C17.8833 13.9123 14.712 17.0837 10.8 17.0837C6.88799 17.0837 3.71667 13.9123 3.71667 10.0003Z"
                                        fill="#97A0A6" />
                                    <ellipse cx="10.8" cy="6.66634" rx="0.833333" ry="0.833333" fill="#97A0A6" />
                                    <path
                                        d="M9.96667 9.16634C9.96667 8.7061 10.3398 8.33301 10.8 8.33301C11.2602 8.33301 11.6333 8.7061 11.6333 9.16634V13.333C11.6333 13.7932 11.2602 14.1663 10.8 14.1663C10.3398 14.1663 9.96667 13.7932 9.96667 13.333V9.16634Z"
                                        fill="#97A0A6" />
                                </svg>

                            </span>
                        </div>
                        <div class="flex items-center mt-1">
                            <span class="text-lg font-bold text-gray-900">{{ formattedMetrics[0].formattedValue
                                }}</span>
                        </div>
                    </div>
                </div>
                <ngx-charts-bar-vertical [view]="chartView" [scheme]="chartColorScheme" [results]="chartData"
                    [xAxis]="true" [yAxis]="true" [legend]="false" [showXAxisLabel]="true"
                    [xAxisLabel]="chartXAxisLabel" [showYAxisLabel]="true" [yAxisLabel]="chartYAxisLabel"
                    [animations]="true">
                </ngx-charts-bar-vertical>
            </div>

            <!-- Profile Views -->
            <div class="bg-white shadow rounded-lg p-4">
                <div class="flex items-center pb-5 mb-5 border-b border-gray-300">
                    <!-- Icon -->
                    <div class="w-10 h-10 flex items-center justify-center rounded-full bg-primary text-white">
                        <div class="w-10 h-10 flex items-center justify-center rounded-full bg-primary text-white">
                            <svg width="25" height="24" class="h-6 w-6" viewBox="0 0 25 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M6.45837 9.77344C6.45837 9.35922 6.80536 9.02344 7.23337 9.02344H17.5667C17.9947 9.02344 18.3417 9.35922 18.3417 9.77344C18.3417 10.1877 17.9947 10.5234 17.5667 10.5234H7.23337C6.80536 10.5234 6.45837 10.1877 6.45837 9.77344Z"
                                    fill="white" />
                                <path
                                    d="M7.23337 12.0234C6.80536 12.0234 6.45837 12.3592 6.45837 12.7734C6.45837 13.1877 6.80536 13.5234 7.23337 13.5234H12.4C12.828 13.5234 13.175 13.1877 13.175 12.7734C13.175 12.3592 12.828 12.0234 12.4 12.0234H7.23337Z"
                                    fill="white" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M2.58337 5.53125C2.58337 4.28861 3.62431 3.28125 4.90837 3.28125H19.8917C21.1757 3.28125 22.2167 4.28861 22.2167 5.53125V16.0796C22.2167 17.3223 21.1757 18.3296 19.8917 18.3296H15.6048L13.0226 21.6956C12.8764 21.8861 12.6457 21.9985 12.4003 21.9985C12.1551 21.9985 11.9243 21.8861 11.7781 21.6956L9.19599 18.3296H4.90837C3.62431 18.3296 2.58337 17.3223 2.58337 16.0796V5.53125ZM4.90837 4.78125C4.48036 4.78125 4.13337 5.11704 4.13337 5.53125V16.0796C4.13337 16.4938 4.48036 16.8296 4.90837 16.8296H9.58586C9.83113 16.8296 10.0619 16.942 10.2081 17.1326L12.4003 19.9903L14.5927 17.1326C14.7389 16.942 14.9696 16.8296 15.2149 16.8296H19.8917C20.3197 16.8296 20.6667 16.4938 20.6667 16.0796V5.53125C20.6667 5.11704 20.3197 4.78125 19.8917 4.78125H4.90837Z"
                                    fill="white" />
                            </svg>

                        </div>

                    </div>
                    <div class="ml-4">
                        <div class="flex items-center">
                            <span class="text-sm font-medium text-gray-700">{{metrics[1].title}}</span>
                            <span class="ml-1 text-gray-400">
                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M10.8 1.66699C6.19763 1.66699 2.46667 5.39795 2.46667 10.0003C2.46667 14.6027 6.19763 18.3337 10.8 18.3337C15.4023 18.3337 19.1333 14.6027 19.1333 10.0003C19.1333 5.39795 15.4023 1.66699 10.8 1.66699ZM3.71667 10.0003C3.71667 6.08831 6.88799 2.91699 10.8 2.91699C14.712 2.91699 17.8833 6.08831 17.8833 10.0003C17.8833 13.9123 14.712 17.0837 10.8 17.0837C6.88799 17.0837 3.71667 13.9123 3.71667 10.0003Z"
                                        fill="#97A0A6" />
                                    <ellipse cx="10.8" cy="6.66634" rx="0.833333" ry="0.833333" fill="#97A0A6" />
                                    <path
                                        d="M9.96667 9.16634C9.96667 8.7061 10.3398 8.33301 10.8 8.33301C11.2602 8.33301 11.6333 8.7061 11.6333 9.16634V13.333C11.6333 13.7932 11.2602 14.1663 10.8 14.1663C10.3398 14.1663 9.96667 13.7932 9.96667 13.333V9.16634Z"
                                        fill="#97A0A6" />
                                </svg>

                            </span>
                        </div>
                        <div class="flex items-center mt-1">
                            <span class="text-lg font-bold text-gray-900">{{ formattedMetrics[1].formattedValue
                                }}</span>
                        </div>
                    </div>
                </div>
                <ngx-charts-bar-vertical [view]="chartView" [scheme]="chartColorScheme" [results]="chartData"
                    [xAxis]="true" [yAxis]="true" [legend]="false" [showXAxisLabel]="true"
                    [xAxisLabel]="chartXAxisLabel" [showYAxisLabel]="true" [yAxisLabel]="chartYAxisLabel"
                    [animations]="true">
                </ngx-charts-bar-vertical>
            </div>

            <!-- Clicks -->
            <div class="bg-white shadow rounded-lg p-4">
                <div class="flex items-center pb-5 mb-5 border-b border-gray-300">
                    <!-- Icon -->
                    <div class="w-10 h-10 flex items-center justify-center rounded-full bg-primary text-white">
                        <div class="w-10 h-10 flex items-center justify-center rounded-full bg-primary text-white">
                            <svg width="25" height="24" class="h-6 w-6" viewBox="0 0 25 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M6.45837 9.77344C6.45837 9.35922 6.80536 9.02344 7.23337 9.02344H17.5667C17.9947 9.02344 18.3417 9.35922 18.3417 9.77344C18.3417 10.1877 17.9947 10.5234 17.5667 10.5234H7.23337C6.80536 10.5234 6.45837 10.1877 6.45837 9.77344Z"
                                    fill="white" />
                                <path
                                    d="M7.23337 12.0234C6.80536 12.0234 6.45837 12.3592 6.45837 12.7734C6.45837 13.1877 6.80536 13.5234 7.23337 13.5234H12.4C12.828 13.5234 13.175 13.1877 13.175 12.7734C13.175 12.3592 12.828 12.0234 12.4 12.0234H7.23337Z"
                                    fill="white" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M2.58337 5.53125C2.58337 4.28861 3.62431 3.28125 4.90837 3.28125H19.8917C21.1757 3.28125 22.2167 4.28861 22.2167 5.53125V16.0796C22.2167 17.3223 21.1757 18.3296 19.8917 18.3296H15.6048L13.0226 21.6956C12.8764 21.8861 12.6457 21.9985 12.4003 21.9985C12.1551 21.9985 11.9243 21.8861 11.7781 21.6956L9.19599 18.3296H4.90837C3.62431 18.3296 2.58337 17.3223 2.58337 16.0796V5.53125ZM4.90837 4.78125C4.48036 4.78125 4.13337 5.11704 4.13337 5.53125V16.0796C4.13337 16.4938 4.48036 16.8296 4.90837 16.8296H9.58586C9.83113 16.8296 10.0619 16.942 10.2081 17.1326L12.4003 19.9903L14.5927 17.1326C14.7389 16.942 14.9696 16.8296 15.2149 16.8296H19.8917C20.3197 16.8296 20.6667 16.4938 20.6667 16.0796V5.53125C20.6667 5.11704 20.3197 4.78125 19.8917 4.78125H4.90837Z"
                                    fill="white" />
                            </svg>

                        </div>

                    </div>
                    <div class="ml-4">
                        <div class="flex items-center">
                            <span class="text-sm font-medium text-gray-700">{{metrics[2].title}}</span>
                            <span class="ml-1 text-gray-400">
                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M10.8 1.66699C6.19763 1.66699 2.46667 5.39795 2.46667 10.0003C2.46667 14.6027 6.19763 18.3337 10.8 18.3337C15.4023 18.3337 19.1333 14.6027 19.1333 10.0003C19.1333 5.39795 15.4023 1.66699 10.8 1.66699ZM3.71667 10.0003C3.71667 6.08831 6.88799 2.91699 10.8 2.91699C14.712 2.91699 17.8833 6.08831 17.8833 10.0003C17.8833 13.9123 14.712 17.0837 10.8 17.0837C6.88799 17.0837 3.71667 13.9123 3.71667 10.0003Z"
                                        fill="#97A0A6" />
                                    <ellipse cx="10.8" cy="6.66634" rx="0.833333" ry="0.833333" fill="#97A0A6" />
                                    <path
                                        d="M9.96667 9.16634C9.96667 8.7061 10.3398 8.33301 10.8 8.33301C11.2602 8.33301 11.6333 8.7061 11.6333 9.16634V13.333C11.6333 13.7932 11.2602 14.1663 10.8 14.1663C10.3398 14.1663 9.96667 13.7932 9.96667 13.333V9.16634Z"
                                        fill="#97A0A6" />
                                </svg>

                            </span>
                        </div>
                        <div class="flex items-center mt-1">
                            <span class="text-lg font-bold text-gray-900">{{ formattedMetrics[2].formattedValue
                                }}</span>
                        </div>
                    </div>
                </div>
                <ngx-charts-bar-vertical [view]="chartView" [scheme]="chartColorScheme" [results]="chartData"
                    [xAxis]="true" [yAxis]="true" [legend]="false" [showXAxisLabel]="true"
                    [xAxisLabel]="chartXAxisLabel" [showYAxisLabel]="true" [yAxisLabel]="chartYAxisLabel"
                    [animations]="true">
                </ngx-charts-bar-vertical>
            </div>
        </div>

        <!-- Action Buttons Clicks -->
        <div class="bg-white shadow rounded-lg p-4 mb-6">
            <h2 class="text-lg font-semibold mb-4">Clicks on action buttons</h2>
            <div class="grid grid-cols-2 gap-4">
                <div *ngFor="let buttonClick of actionButtonsClicks">
                    <p>{{ buttonClick.label }}: <strong>{{ buttonClick.value }}</strong></p>
                </div>
            </div>
        </div>

        <!-- Boost Section -->
        <div class="bg-yellow-200 text-center p-6 rounded-lg mb-6">
            <p class="text-lg font-semibold">
                Get more clicks by boosting your ranking on
                <span class="text-blue-600">goldenpages.be!</span>
            </p>
            <button class="mt-4 px-4 py-2 bg-primary text-white font-semibold rounded hover:bg-primary-light"
                (click)="boostRanking()">
                Boost
            </button>
        </div>

        <!-- Reviews -->
        <div class="bg-white shadow rounded-lg p-4 mb-6">
            <h2 class="text-lg font-semibold mb-4">Reviews</h2>
            <p>Number of reviews: <strong>{{ reviews.count }}</strong></p>
            <p>Average Review Score: <strong>{{ reviews.average }}</strong></p>
        </div>

        <!-- More Reviews Section -->
        <div class="bg-blue-100 text-center p-6 rounded-lg">
            <p class="text-lg font-semibold">You want more reviews?</p>
            <button class="mt-4 px-4 py-2 bg-primary text-white font-semibold rounded hover:bg-primary-light"
                (click)="learnMore()">
                Learn
            </button>
        </div>
    </div>

</div>