import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SidebarService } from '../../../services/sidebar.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent {
  constructor(public translate: TranslateService, public sidebar: SidebarService){}
}
