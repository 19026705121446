import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { BaseAuthGuard } from './guards/base-auth.guard';
import { AnalyticsComponent } from './pages/analytics/analytics.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { CompanyProfileComponent } from './pages/company-profile/company-profile.component';
import { FormComponent } from './pages/form/form.component';

const routes: Routes = [
  {
    path: 'profile',
    component: CompanyProfileComponent,
    canActivate: [BaseAuthGuard]
  },
  //{
    //path: 'analytics',
    //component: AnalyticsComponent,
    //canActivate: [BaseAuthGuard]
  //},
  {
    path: 'login',
    data: {"loginButton": "disabled" },
    component: LoginComponent
  },
  {
    path: 'dash',
    pathMatch: 'full',
    component: DashboardComponent,
    canActivate: [BaseAuthGuard]
  },
  {
    path: 'edit/:id',
    component: FormComponent,
    canActivate: [BaseAuthGuard]
  },
  {
    path: '**',
    redirectTo: '/dash'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
