import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "@fcr/azure-auth";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class BaseAuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    router: RouterStateSnapshot
  ): boolean | Promise<boolean> | Observable<boolean> {

    //let reqRights = this.recudeRights(route.data ? route.data.rights : null);

    //TODO: Check rights by matrix form backend

    if (!this.authService.isUserLoggedIn()) {
        //console.log("isn't logged in")
      this.router.navigate(["/login"]);
      return false;
    }

    return true;
  }

}