import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SidebarService{
  private displayed: boolean = true;

  isDisplayed() {  
    return this.displayed
  }

  toggleSidebar() {
    this.displayed = !this.displayed
  }
}
