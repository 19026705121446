<div class="fixed inset-0 bg-black bg-opacity-50 z-modal flex justify-center items-center">
    <div class="bg-white rounded-lg shadow-lg p-6 w-11/12 max-w-md relative">
        <!-- Modal Content -->
        <p *ngIf="!this.translateMessage" class="text-lg text-gray-800">{{ message }}</p>
        <p *ngIf="this.translateMessage" class="text-lg text-gray-800">{{ message | translate }}</p>

        <!-- Close Button -->
        <button (click)="onClose()"
            class="absolute top-3 right-3 text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-300 rounded-full p-2"
            aria-label="Close">
            ✖
        </button>
    </div>
</div>