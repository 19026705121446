<div id="language-switch" class="relative h-full">
  <!-- Selected language -->
  <div
    (click)="handleOpenLanguageSwitch()"
    [ngClass]="{
      'dropdown-open': this.isOpen
    }"
    class="btn btn--large btn--outline flex items-center">
    <span class="flex gap-2 items-center flex-grow ml-2">
      <!-- Circular flag for the selected language -->
      <img
        [src]="'assets/icons/flags/'+ selectedLanguage + '.svg'"
        alt="{{ selectedLanguage }} flag"
        class="w-6 h-6 rounded-full object-cover"
      />
      <span class="lg:text-base text-sm uppercase text-left">{{ selectedLanguage }}</span>
    </span>
    <app-icon
      [icon]="'arrow-down-icon'"
      [ngClass]="{ 'rotate-180': isOpen }"
      svgClass="w-3 h-2 ml-auto mr-2 transform translation"
    ></app-icon>
  </div>

  <!-- Possible languages -->
  <ul class="lang-switch-dropdown" *ngIf="isOpen">
    <li
      *ngFor="let language of filteredLanguages"
      class="flex items-center gap-2 text-left ml-3">
      <!-- Circular flag for each language -->
      <img
        [src]="'assets/icons/flags/'+ language + '.svg'"
        alt="{{ language }} flag"
        class="w-6 h-6 rounded-full object-cover"
      />
      <span
        (click)="handleSelectLanguage(language)"
        class="block hover:underline uppercase">
        {{ language }}
      </span>
      <span style="width: 12px;"></span>
    </li>
  </ul>
</div>