<div class="flex-row justify-items-center">
    <app-skeleton *ngIf="this.loading" class="h-screen"></app-skeleton>
    <form [formGroup]="this.form" *ngIf="!this.loading"
        class="fcr-your-business-form bg-white mt-5 rounded-lg max-w-xl">

        <section class="form-section">

            <!-- enterpriseNumber -->

            <div class="floating-label-input" [ngClass]="{ 'invalid' : !this.isFieldValid('enterpriseNumber') }"
                *ngIf="this.enterpriseNumberVisible">
                <input type="text" name="enterpriseNumber" id="enterpriseNumber" formControlName="enterpriseNumber"
                    [ngClass]="{ 
                        'focused' : !!this.form.get('enterpriseNumber')?.value, 
                        'invalid' : !this.isFieldValid('enterpriseNumber') 
                    }">
                <label for="enterpriseNumber">
                    {{ "fields.enterpriseNumber.label" | translate }} <span class="red-star">*</span>
                </label>
                <button (click)="clearValue('enterpriseNumber')" *ngIf="!!this.form.get('enterpriseNumber')?.value">
                    <div class="cross"></div>
                </button>
            </div>
            <div class="error-message" *ngIf="!this.isFieldValid('enterpriseNumber')">
                <div class="warning-icon"></div> {{ this.getErrorFor('enterpriseNumber') | translate }}
            </div>

            <!-- kvkNumber -->

            <div class="floating-label-input" [ngClass]="{ 'invalid' : !this.isFieldValid('kvkNumber') }"
                *ngIf="this.kvkNumberVisible">
                <input type="text" name="kvkNumber" id="kvkNumber" formControlName="kvkNumber" [ngClass]="{ 
                        'focused' : !!this.form.get('kvkNumber')?.value, 
                        'invalid' : !this.isFieldValid('kvkNumber') 
                    }">
                <label for="kvkNumber">
                    {{ "fields.kvkNumber.label" | translate }} <span class="red-star">*</span>
                </label>
                <button (click)="clearValue('kvkNumber')" *ngIf="!!this.form.get('kvkNumber')?.value">
                    <div class="cross"></div>
                </button>
            </div>
            <div class="error-message" *ngIf="!this.isFieldValid('kvkNumber')">
                <div class="warning-icon"></div> {{ this.getErrorFor('kvkNumber') | translate }}
            </div>

            <!-- organisationName -->

            <div class="floating-label-input" [ngClass]="{ 'invalid' : !this.isFieldValid('organisationName') }">
                <input type="text" name="organisationName" id="organisationName" formControlName="organisationName"
                    [ngClass]="{ 
                        'focused' : !!this.form.get('organisationName')?.value, 
                        'invalid' : !this.isFieldValid('organisationName') 
                    }">
                <label for="organisationName">
                    {{ "fields.organisationName.label" | translate }} <span class="red-star">*</span>
                </label>
                <button (click)="clearValue('organisationName')" *ngIf="!!this.form.get('organisationName')?.value">
                    <div class="cross"></div>
                </button>
            </div>
            <div class="error-message" *ngIf="!this.isFieldValid('organisationName')">
                <div class="warning-icon"></div> {{ this.getErrorFor('organisationName') | translate }}
            </div>

            <!-- headingId -->
            <div class="form-control">
                <div class="custom-error-wrapper">
                    <mat-form-field floatLabel="always" appearance="outline">
                        <mat-label>{{ "fields.headingId.label" | translate }}</mat-label>
                        <mat-select [formControl]="this.headingCtrl" placeholder="" #headingSelect>
                            <mat-option>
                                <ngx-mat-select-search [formControl]="this.headingFilterCtrl"
                                    [placeholderLabel]="('fields.headingId.placeholder' | translate)"
                                    [noEntriesFoundLabel]="('fields.headingId.noMatch' | translate)"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let heading of this.filteredHeadings | async" [value]="heading">
                                {{heading.headingName}}
                            </mat-option>
                        </mat-select>
                        <mat-hint>{{ "hints.published" | translate }}</mat-hint>
                        <button matSuffix mat-icon-button aria-label="Clear" (click)="clearValue('headingId')"
                            *ngIf="!!this.form.get('headingId')?.value">
                            <mat-icon svgIcon="close"></mat-icon>
                        </button>
                        <!-- <mat-error>
                            {{ this.getErrorFor('headingId') | translate }}
                        </mat-error> -->
                    </mat-form-field>
                    <div class="error-message" *ngIf="!this.isFieldValid('headingId')">
                        {{ this.getErrorFor('headingId') | translate }}
                    </div>
                </div>
            </div>

            <!-- ADDRESS ------------------------------- -->

            <!-- address : search -->
            <div class="form-control">
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{ "fields.addressSearch.label" | translate }}</mat-label>
                    <!-- <input matInput formControlName="addressSearch" #addressAutocomplete /> -->
                    <input matInput formControlName="addressSearch" #addressAutocomplete
                        [matAutocomplete]="addressSuggest" />
                    <mat-autocomplete #addressSuggest="matAutocomplete" [displayWith]="selectAddressFn">
                        <mat-option *ngFor="let address of addressSuggestOptions"
                            [value]="address">{{address.description}}</mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>

            <!-- street row -->
            <div class="row row--street form-control">
                <!-- addressStreet -->
                <div class="custom-error-wrapper">
                    <mat-form-field floatLabel="always" appearance="outline">
                        <mat-label>{{ "fields.addressStreet.label" | translate }}</mat-label>
                        <input matInput formControlName="addressStreet" />
                        <!-- <mat-error>
                            {{ this.getErrorFor('addressStreet') | translate }}
                        </mat-error> -->
                    </mat-form-field>
                    <div class="error-message" *ngIf="!this.isFieldValid('addressStreet')">
                        {{ this.getAddressErrorFor('addressStreet') | translate }}
                    </div>
                </div>

                <!-- addressStreetNumber -->
                <div class="custom-error-wrapper">
                    <mat-form-field floatLabel="always" appearance="outline">
                        <mat-label>{{ "fields.addressStreetNumber.label" | translate }}</mat-label>
                        <input matInput formControlName="addressStreetNumber" />
                        <!-- <mat-error>
                            {{ this.getErrorFor('addressStreetNumber') | translate }}
                        </mat-error> -->
                    </mat-form-field>
                    <div class="error-message" *ngIf="!this.isFieldValid('addressStreetNumber')">
                        {{ this.getAddressErrorFor('addressStreetNumber') | translate }}
                    </div>
                </div>

                <!-- addressStreetDoor -->
                <div class="custom-error-wrapper">
                    <mat-form-field floatLabel="always" appearance="outline">
                        <mat-label>{{ "fields.addressStreetDoor.label" | translate }}</mat-label>
                        <input matInput formControlName="addressStreetDoor" />
                        <!-- <mat-error>
                            {{ this.getErrorFor('addressStreetDoor') | translate }}
                        </mat-error> -->
                    </mat-form-field>
                    <div class="error-message" *ngIf="!this.isFieldValid('addressStreetDoor')">
                        {{ this.getAddressErrorFor('addressStreetDoor') | translate }}
                    </div>
                </div>
            </div>
            <!-- .street row -->

            <!-- post row -->
            <div class="row row--post form-control">
                <!-- addressPostalCode -->
                <div class="custom-error-wrapper" [ngClass]="!this.isFieldValid('addressPostalCode') ? 'mb-4' : ''">
                    <mat-form-field floatLabel="always" appearance="outline">
                        <mat-label>{{ "fields.addressPostalCode.label" | translate }}</mat-label>
                        <input matInput formControlName="addressPostalCode" />
                        <!-- <mat-error>
                            {{ this.getErrorFor('addressPostalCode') | translate }}
                        </mat-error> -->
                    </mat-form-field>
                    <div class="error-message" *ngIf="!this.isFieldValid('addressPostalCode')">
                        {{ this.getAddressErrorFor('addressPostalCode') | translate }}
                    </div>
                </div>

                <!-- addressCity -->
                <div class="custom-error-wrapper">
                    <mat-form-field floatLabel="always" appearance="outline">
                        <mat-label>{{ "fields.addressCity.label" | translate }}</mat-label>
                        <input matInput formControlName="addressCity" />
                        <!-- <mat-error>
                            {{ this.getErrorFor('addressCity') | translate }}
                        </mat-error> -->
                    </mat-form-field>
                    <div class="error-message" *ngIf="!this.isFieldValid('addressCity')">
                        {{ this.getAddressErrorFor('addressCity') | translate }}
                    </div>
                </div>

                <!-- addressDistrict -->
                <div class="custom-error-wrapper">
                    <mat-form-field floatLabel="always" appearance="outline">
                        <mat-label>{{ "fields.addressDistrict.label" | translate }}</mat-label>
                        <input matInput formControlName="addressDistrict" />
                        <!-- <mat-error>
                            {{ this.getErrorFor('addressDistrict') | translate }}
                        </mat-error> -->
                    </mat-form-field>
                    <div class="error-message" *ngIf="!this.isFieldValid('addressDistrict')">
                        {{ this.getAddressErrorFor('addressDistrict') | translate }}
                    </div>
                </div>
            </div>
            <!-- .post row -->

            <!-- country -->
            <div class="form-control">
                <div class="custom-error-wrapper">
                    <mat-form-field floatLabel="always" appearance="outline">
                        <mat-label>{{ "fields.addressCountry.label" | translate }}</mat-label>
                        <input matInput type="text" readonly [formControl]="addressCountryNameCtrl"
                            (click)="openCountries()" />
                        <mat-hint>{{ "hints.published" | translate }}</mat-hint>
                        <button matSuffix mat-icon-button aria-label="Clear" (click)="openCountries()">
                            <img src="https://flagcdn.com/{{ this.addressCountry.code.toLocaleLowerCase() }}.svg"
                                height="16" />
                        </button>
                        <!-- <mat-error>
                            {{ this.getErrorFor('addressCountry') | translate }}
                        </mat-error> -->
                    </mat-form-field>
                    <div class="error-message" *ngIf="!this.isFieldValid('addressCountryNameCtrl')">
                        {{ this.getErrorFor('addressCountryNameCtrl') | translate }}
                    </div>
                </div>
            </div>

            <!-- /ADDRESS ------------------------------ -->


            <!-- CONTACTS ------------------------------ -->

            <!-- floating label - email -->
            <div class="floating-label-input" [ngClass]="{ 'invalid' : !this.isFieldValid('email') }">
                <input type="email" name="email" id="email" formControlName="email" [ngClass]="{ 
                        'focused' : !!this.form.get('email')?.value, 
                        'invalid' : !this.isFieldValid('email') 
                    }">
                <label for="email">
                    {{ "fields.email.label" | translate }} <span class="red-star">*</span>
                </label>
                <button (click)="clearValue('email')" *ngIf="!!this.form.get('email')?.value">
                    <div class="cross"></div>
                </button>
            </div>
            <div class="error-message" *ngIf="!this.isFieldValid('email')">
                <div class="warning-icon"></div> {{ this.getErrorFor('email') | translate }}
            </div>
            <!-- /floating label - email -->

            <!-- fixedPhoneNumber -->
            <div class="form-control">
                <app-phone-input class="w-full" [label]="('fields.fixedPhoneNumber.label' | translate)"
                    [countries]="this.countries" [primaryCountry]="this.primaryCountry"
                    formControlName="fixedPhoneNumber" [otherPhoneCtrl]="this.mobilePhoneNumberCtrl"
                    [otherPhone]="mobilePhone" (inputBlur)="updatePhonesValidation()" [showErrors]="this.showErrors"
                    #fixedPhone />
            </div>

            <!-- mobilePhoneNumber -->
            <div class="form-control">
                <app-phone-input class="w-full" [label]="('fields.mobilePhoneNumber.label' | translate)"
                    [countries]="this.countries" [primaryCountry]="this.primaryCountry"
                    formControlName="mobilePhoneNumber" [otherPhoneCtrl]="this.fixedPhoneNumberCtrl"
                    [otherPhone]="fixedPhone" (inputBlur)="updatePhonesValidation()" [showErrors]="this.showErrors"
                    #mobilePhone />
                <!-- 
                        [formControlRef]="this.form.get('mobilePhoneNumber')"
                -->
                <!-- {{ this.form.get('fixedPhoneNumber')?.value }} -->
            </div>
            <!-- {{ this.form.get('fixedPhoneNumber')?.value }} -->

            <!-- website -->
            <div class="floating-label-input" [ngClass]="{ 'invalid' : !this.isFieldValid('website') }">
                <input type="text" name="website" id="website" formControlName="website" [ngClass]="{ 
                        'focused' : !!this.form.get('website')?.value, 
                        'invalid' : !this.isFieldValid('website') 
                    }">
                <label for="website">
                    {{ "fields.website.label" | translate }}
                </label>
                <button (click)="clearValue('website')" *ngIf="!!this.form.get('website')?.value">
                    <div class="cross"></div>
                </button>
            </div>
            <div class="error-message" *ngIf="!this.isFieldValid('website')">
                <div class="warning-icon"></div> {{ this.getErrorFor('website') | translate }}
            </div>

            <!-- webshop -->
            <div class="floating-label-input" [ngClass]="{ 'invalid' : !this.isFieldValid('webshop') }">
                <input type="text" name="webshop" id="webshop" formControlName="webshop" [ngClass]="{ 
                        'focused' : !!this.form.get('webshop')?.value, 
                        'invalid' : !this.isFieldValid('webshop') 
                    }">
                <label for="webshop">
                    {{ "fields.webshop.label" | translate }}
                </label>
                <button (click)="clearValue('webshop')" *ngIf="!!this.form.get('webshop')?.value">
                    <div class="cross"></div>
                </button>
            </div>
            <div class="error-message" *ngIf="!this.isFieldValid('webshop')">
                <div class="warning-icon"></div> {{ this.getErrorFor('webshop') | translate }}
            </div>

            <!-- facebook -->
            <div class="form-control">
                <app-social-input class="w-full" formControlName="facebook"
                    [label]="('fields.facebook.label' | translate)"
                    [placeholder]="( 'fields.facebook.placeholder' | translate )">
                </app-social-input>
            </div>

            <!-- instagram -->
            <div class="form-control">
                <app-social-input class="w-full" formControlName="instagram"
                    [label]="('fields.instagram.label' | translate)"
                    [placeholder]="( 'fields.instagram.placeholder' | translate )">
                </app-social-input>

            </div>

            <!-- linkedIn -->
            <div class="form-control">
                <app-social-input class="w-full" formControlName="linkedIn"
                    [label]="('fields.linkedIn.label' | translate)"
                    [placeholder]="( 'fields.linkedIn.placeholder' | translate )">
                </app-social-input>
            </div>

            <!-- opening hours -->
            <app-opening-hours formControlName="openingHours" [lid]="this.listingId"></app-opening-hours>
            <div class="error-message mt-5" *ngIf="!this.isFieldValid('openingHours')">
                <div class="warning-icon"></div> {{ this.getErrorFor('openingHours') | translate }}
            </div>

            <!-- opening hours, additional text -->
            <div class="floating-label-input"
                [ngClass]="{ 'invalid' : !this.isFieldValid('openingHoursAdditionalInfo') }">
                <input type="text" name="openingHoursAdditionalInfo" id="openingHoursAdditionalInfo"
                    formControlName="openingHoursAdditionalInfo" [ngClass]="{ 
                        'focused' : !!this.form.get('openingHoursAdditionalInfo')?.value, 
                        'invalid' : !this.isFieldValid('openingHoursAdditionalInfo') 
                    }">
                <label for="openingHoursAdditionalInfo">
                    {{ "fields.openingHoursAdditionalInfo.label" | translate }}
                </label>
                <button (click)="clearValue('openingHoursAdditionalInfo')"
                    *ngIf="!!this.form.get('openingHoursAdditionalInfo')?.value">
                    <div class="cross"></div>
                </button>
            </div>
            <div class="error-message" *ngIf="!this.isFieldValid('openingHoursAdditionalInfo')">
                <div class="warning-icon"></div> {{ this.getErrorFor('openingHoursAdditionalInfo') | translate }}
            </div>
        </section>

        <!-- errors -->
        <section *ngIf="this.showErrors && this.getAllErrors().length" class="form-section">
            <mat-error *ngFor="let error of this.getAllErrors()">{{ error | translate }}</mat-error>
            <div class="error-message" *ngFor="let error of this.getAllErrors()">
                <div class="warning-icon"></div> {{ error | translate }}
            </div>
        </section>

        <!-- submit -->
        <section class="text-center flex justify-items-center justify-center gap-x-3 mb-4">
            <a class="btn" (click)="postData()">
                {{ "form.submit" | translate }}
            </a>
            <a class="btn btn-cancel" (click)="cancelEdit()">
                {{ "form.cancel" | translate }}
            </a>
        </section>
        <!-- submit -->
    </form>
</div>