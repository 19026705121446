import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-completion-hint',
  templateUrl: './completion-hint.component.html',
  styleUrl: './completion-hint.component.scss'
})
export class CompletionHintComponent {

  @Input("text")
  text: string;

  @Input("percentage")
  percentage: number;

  @Input("dashboard")
  isDashboard: boolean = true;

  constructor(public translate: TranslateService) { }

}
