import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modalVisibility = new Subject<{ message: string | null; route?: string; translateMessage?: boolean }>();
  modal$ = this.modalVisibility.asObservable();

  open(message: string, translateMessage: boolean = false, route?: string, ): void {
    this.modalVisibility.next({ message, route, translateMessage});
  }

  close(): void {
    this.modalVisibility.next({ message: null });
  }
}