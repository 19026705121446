import { AfterViewInit, Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { SidebarService } from '../../services/sidebar.service';

@Component({
  selector: 'app-sticky-element',
  templateUrl: './sticky-element.component.html',
  styleUrl: './sticky-element.component.scss'
})
export class StickyElementComponent implements AfterViewInit {
  @Input('stickyClass') 
  stickyClass: string = 'sticky';

  @Input('isLoginPage')
  isLoginPage: boolean = false;

  sticky: boolean = true;
  placeholderHeight: number = 0;

  @ViewChild('element') 
  element: ElementRef;

  elementPosition: any;

  constructor(public sidebar: SidebarService) {}

  ngAfterViewInit(): void {
    this.elementPosition = this.element.nativeElement.offsetTop;
    this.calculatePlaceholderHeight();

  }

  calculatePlaceholderHeight() {
    this.placeholderHeight = this.sticky ? this.element.nativeElement.offsetHeight : 0;
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    const windowScroll = window.pageYOffset;
    this.sticky = windowScroll >= this.elementPosition;
    this.calculatePlaceholderHeight();
  }
}
