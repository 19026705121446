import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
})
export class AnalyticsComponent implements OnInit {
  // Dynamic Data
  companies = [
    { name: 'Company name Antwerp Office', address: 'Address 1' },
    { name: 'Company B', address: 'Address 2' },
    { name: 'Company C', address: 'Address 3' },
  ];
  periods = [
    { label: 'Last 6 months' },
    { label: 'Last Year' },
    { label: 'Last 3 Months' },
  ];
  selectedCompany = this.companies[0]; // Default selected company
  selectedPeriod = this.periods[0]; // Default selected period

  // Dropdown state
  isCompanyDropdownOpen = false;
  isPeriodDropdownOpen = false;

  // Metrics and other data
  metrics = [
    { title: 'Impressions', value: 12354 },
    { title: 'Profile views', value: 8456 },
    { title: 'Clicks', value: 2489 },
  ];

  formatNumberWithSpaces(value: number): string {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  formattedMetrics = this.metrics.map((metric) => ({
    ...metric,
    formattedValue: this.formatNumberWithSpaces(metric.value),
  }));

  actionButtonsClicks = [
    { label: 'E-mail/Write', value: 1320 },
    { label: 'Call', value: 1565 },
    { label: 'Website', value: 912 },
    { label: 'Map', value: 123 },
    { label: 'Share', value: 984 },
    { label: 'Webshop', value: 79 },
  ];

  reviews = {
    count: 198,
    average: 4.5,
  };

  // Data for charts
  chartData: any[] = [
    { name: 'January', value: 500 },
    { name: 'February', value: 1200 },
    { name: 'March', value: 750 },
    { name: 'April', value: 485 },
    { name: 'May', value: 1020 },
    { name: 'June', value: 865 },
  ];

  chartView: [number, number] = [400, 200]; // Chart size

  chartColorScheme: string = 'vivid'; // Use a predefined color scheme
  chartXAxisLabel = 'Month';
  chartYAxisLabel = 'Views';

  constructor() {}

  ngOnInit(): void {}

  // Methods to toggle dropdowns
  toggleCompanyDropdown(): void {
    this.isCompanyDropdownOpen = !this.isCompanyDropdownOpen;
  }

  togglePeriodDropdown(): void {
    this.isPeriodDropdownOpen = !this.isPeriodDropdownOpen;
  }

  // Methods to handle selections
  selectCompany(company: any): void {
    this.selectedCompany = company;
    this.isCompanyDropdownOpen = false;
    this.onCompanyChange();
  }

  selectPeriod(period: any): void {
    this.selectedPeriod = period;
    this.isPeriodDropdownOpen = false;
    this.onPeriodChange();
  }

  // Callback methods for selection changes
  onCompanyChange(): void {
    //console.log(`Selected Company: ${this.selectedCompany.name}`);
    // Add logic to fetch new data based on company selection
  }

  onPeriodChange(): void {
    //console.log(`Selected Period: ${this.selectedPeriod.label}`);
    // Add logic to fetch new data based on period selection
  }

  // Additional actions
  boostRanking(): void {
    //console.log('Boost ranking clicked!');
    // Add logic to boost ranking
  }

  learnMore(): void {
    //console.log('Learn more about reviews clicked!');
    // Add logic for "Learn More" functionality
  }
}