<!-- Sidebar -->
<aside class="h-screen flex flex-col pt-2 overflow-y-auto" [ngClass]="this.sidebar.isDisplayed() ? 'w-64': 'w-16'">
    <!-- Navigation Links -->
    <nav class="w-full flex-col">
        <!-- logo -->
        <a [routerLink]="'/'" [ariaLabel]="'GoldenPages' | translate"
            class="flex md:flex-col items-baseline"
            [ngClass]="this.sidebar.isDisplayed() ? 'ml-4' : 'ml-2'">
            <img src="/assets/images/fcr-logo.png" alt="" class="w-10 h-10 my-2">
        </a>
        <a routerLink="/dash" routerLinkActive="text-fcr-blue" class="flex items-center py-3 px-5 text-gray-700 hover:bg-gray-200 w-full">
            <i class="fas fa-th-large mr-3 "></i>
            <span *ngIf="this.sidebar.isDisplayed()">{{ "header.nav.dashboard" | translate }}</span>
        </a>
        <a routerLink="/profile" routerLinkActive="text-fcr-blue" class="flex items-center py-3 px-5 text-gray-700 hover:bg-gray-200 w-full">
            <i class="fas fa-building mr-3"></i>
            <span *ngIf="this.sidebar.isDisplayed()">{{ "header.nav.profile" | translate }}</span>
        </a>
        <!--
        <a routerLink="/analytics" routerLinkActive="text-fcr-blue" class="flex items-center py-3 px-5 text-gray-700 hover:bg-gray-200 w-full">
            <i class="fas fa-chart-line mr-3"></i>
            <span *ngIf="this.sidebar.isDisplayed()">{{ "header.nav.statistics" | translate }}</span>
        </a>
        -->
    </nav>
</aside>