import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService{
    private storageKey = "SCALEUP_FAVOURITE";
    private lastListing;
  
    constructor() {
        this.lastListing = localStorage.getItem(this.storageKey) ? localStorage.getItem(this.storageKey) : '';
    }
  
    get favouriteId(): string {
      return this.lastListing
    }
  
    isFavourite(id: string): boolean {
      return this.lastListing == id 
    }
  
    updateFavourite(id: string): boolean {
      if (this.isFavourite(id)) {
        return false;
      }

      this.updateStorage(id);
      return true;
    }
  
    private updateStorage(favId): void {
      localStorage.setItem(this.storageKey, favId);
      this.lastListing = favId;
    }
}
