import { Component, OnInit } from '@angular/core';
import { HPDataModel } from '../../models/hp-data.model';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.scss']
})
export class CompanyProfileComponent implements OnInit{

  currentPage = 1;
  totalPages: number[] = [];

  data: HPDataModel = new HPDataModel();
  loading: boolean = false;

  constructor(private dataService: DataService) {}

  ngOnInit(): void {
    this.getListings();
  }

  changePage(page: number) {
    this.currentPage = page;
  }

  getListings() {
    this.dataService.getHomePageData().subscribe(data => {
      this.data = data;

      this.setTotalPages()
    });
  }

  setTotalPages() {
    const maxItemsPerPage = 6;
    const totalPageCount = Math.ceil(this.data.count / maxItemsPerPage);
    this.totalPages = Array.from({ length: totalPageCount }, (_, i) => i + 1);
  }
}