<h1 class="text-2xl font-semibold mb-4 mt-3 lg:mt-0 ml-2 lg:ml-0">{{ "dashboard.welcome" | translate }}</h1>
<div class="flex md:flex-wrap">
    <!-- Left column -->
    <div class="w-full lg:w-2/3 lg:pr-5">
        <app-skeleton *ngIf="this.loading" class="w-100 h-96"></app-skeleton>
        <div class="shadow-md w-full" *ngIf="!this.loading">
            <div class="rounded-lg bg-white p-5">
                <div class="form-group">
                    <div class="custom-dropdown">
                        <!-- Toggle Button -->
                        <button id="companySelect" class="dropdown-toggle flex items-center gap-4" (click)="toggleDropdown()">
                            <div>
                                <span class="fw-bold d-block">{{ this.selectedListing.organisationName }}</span>
                                <span class="">{{ this.selectedListing.addressStreet }} 
                                    {{ this.selectedListing.addressStreetNumber }}, 
                                    {{ this.selectedListing.addressPostalCode}}
                                    {{ this.selectedListing.addressCity }}
                                </span>
                            </div>
                            <div class="flex justify-items-center items-center">
                                <i class="fa-solid fa-chevron-down text-gray-600"></i>
                            </div>
                        </button>
        
                        <!-- Dropdown Menu -->
                        <ul class="dropdown-menu" [class.show]="isDropdownOpen" *ngIf="this.hpData.count > 1">
                            <li *ngFor="let listing of this.hpData.listings" class="dropdown-item" (click)="selectListing(listing.id)">
                                <span class="fw-bold d-block">{{ listing.companyName }}</span>
                                <span class="text-muted small">{{ listing.address.street }} 
                                    {{ listing.address.housenumber }}, 
                                    {{ listing.address.city }}
                                    {{ listing.address.street }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
        
        
        
            </div>
        
            <div class="bg-secondary-blue p-5">
                <div class="flex justify-between items-start mb-5">
                    <p class="text-left">{{ "dashboard.profileStrength.text" | translate }} <a [href]="'dashboard.profileStrength.urlLink' | translate"
                            target="_blank" class="underline">{{ "dashboard.profileStrength.urlText" | translate }}</a></p>
        
                </div>
        
                <div class="flex flex-col lg:flex-row mb-4">
                    <div class="lg:w-64">
                        <app-percentage-gauge [percentage]="this.selectedListing.profileCompletenessPercentage"></app-percentage-gauge>
                    </div>
                    <div *ngIf="selectedListing.profileCompleteness.length > 0">
                        <p>{{ "tips.label" | translate}}:</p>
                        <div *ngIf="showLessTips; else elseBlock">
                            <app-completion-hint [text]="this.selectedListing.profileCompleteness[0].key" [percentage]="this.selectedListing.profileCompleteness[0].weight"></app-completion-hint>
                        </div>
                        <ng-template #elseBlock>
                            <app-completion-hint *ngFor="let tip of this.selectedListing.profileCompleteness" [text]="tip.key"
                                [percentage]="tip.weight"></app-completion-hint>
                        </ng-template>
                        <a *ngIf="this.selectedListing.profileCompleteness.length > 1 && showLessTips" (click)="showLessTips = !showLessTips" class="underline pointer-click"> 
                            {{ 'tips.showMore' | translate}}</a>
                        <a *ngIf="this.selectedListing.profileCompleteness.length > 1 && !showLessTips" (click)="showLessTips = !showLessTips" class="underline pointer-click"> 
                            {{ 'tips.showLess' | translate}}</a>
                    </div>
                </div>
                <!--
                <div class="grid lg:grid-cols-2 w-full mb-5">
                    <app-review-card [reviewCount]="120" [averageRating]="3.5"></app-review-card>
                </div>
                -->
                <div class="flex flex-col lg:flex-row justify-between lg:items-start mt-5">
                    <div class="flex space-x-4 mb-4 lg:mb-0">
                        <!-- More statistics button 
                        <button
                            class="px-4 py-2 border border-gray-300 text-black bg-white rounded-lg shadow hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
                            More statistics
                        </button>
                        -->
                        <!-- Edit button -->
                        <a class="px-4 py-2 text-white bg-primary rounded-lg shadow hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                            [routerLink]="['/edit', selectedListing.listingId]">
                            <i class="fas fa-pen mr-2"></i>{{"dashboard.edit" | translate}}
                        </a>
                    </div>
            
                    <div class="grid lg:place-content-end"> 
                        <a class="underline text-gray-600" (click)="seeOnline('dashboard.viewProfile.link')">{{ "dashboard.viewProfile.label" | translate }}</a>
                    </div>
                </div>
            </div>
        
        </div>
    </div>
    <!-- Right column -->
    <div class="w-1/3 pl-5 hidden lg:block">
        
    </div>
</div>