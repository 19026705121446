import { Component, Inject, Input, Optional, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, FormGroup, NgControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MAT_FORM_FIELD, MatFormField } from '@angular/material/form-field';
import { OpeningHoursItem, WeekDayEnum } from '../../../models/form-data.model';

@Component({
  selector: 'app-opening-hours',
  templateUrl: './opening-hours.component.html',
  styleUrls: ['./opening-hours.component.scss']
})
export class OpeningHoursComponent implements ControlValueAccessor {
  touched = false;
  onChange = (_: any) => { };
  onTouched = () => { };
  form: FormGroup;
  nonstopCntr = new FormControl(false);
  week: OpeningHoursDay[];
  backupCurrentWeekData: OpeningHoursDay[] = [];

  @Input("lid")
  listingId: string = ''

  constructor(
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.form = new FormGroup({
      nonstop: this.nonstopCntr,
    });

    this.week = this.defaultWeek();
    this.nonstopCntr.valueChanges.subscribe(val => {
      // backup data if user switch nonstop mode
      if (!val) {
        this.week = this.backupCurrentWeekData.length ? this.backupCurrentWeekData : this.defaultWeek();
      } else {
        this.backupCurrentWeekData = this.week;
        this.writeNonstop();
      }
      this.updateControlValue();
    });
  }

  writeValue(val: Array<OpeningHoursItem>): void {
    //console.log('OH set value', val)

    // detect nonstop
    if (val.length > 0 && val.length == val.filter(v => (v.fromTime == "0:00" || v.fromTime == "00:00") && (v.tillTime == "24:00" || v.tillTime == "23:59")).length) {
      // commented out as we want to unchecked as default value
      if(this.listingId && this.listingId != '')
        this.nonstopCntr.setValue(true);

      return;
    }

    // write week
    var week = new Array<OpeningHoursDay>();
    week.push(this.writeDay(val, WeekDayEnum.Monday));
    week.push(this.writeDay(val, WeekDayEnum.Tuesday));
    week.push(this.writeDay(val, WeekDayEnum.Wednesday));
    week.push(this.writeDay(val, WeekDayEnum.Thursday));
    week.push(this.writeDay(val, WeekDayEnum.Friday));
    week.push(this.writeDay(val, WeekDayEnum.Saturday));
    week.push(this.writeDay(val, WeekDayEnum.Sunday));
    this.week = week;

    // console.log('OH loaded', week)


  }

  writeNonstop() {
    this.week = this.defaultWeek();
    this.week.forEach(day => {
      day.enabled.setValue(true);
      day.enabled.disable();
      day.segments.forEach(s => {
        s.timeFrom.setValue("00:00");
        s.timeFrom.disable();
        s.timeTill.setValue("23:59");
        s.timeTill.disable();
      });
    });
  }

  writeDay(val: Array<OpeningHoursItem>, weekDay: WeekDayEnum): OpeningHoursDay {
    const day = new OpeningHoursDay(this, weekDay);
    const items = val.filter(v => v.weekDay == weekDay);

    if (items && items.length > 0) {
      // we have data for segment - enable segment and setup data
      day.segments = items.map(i => new OpeningHoursSegment(this, day, i.fromTime, i.tillTime));
      day.enabled.setValue(true);
    } else {
      // we do not have data for segment - disable segment and setup empty data
      day.enabled.setValue(false);
      day.segments = [new OpeningHoursSegment(this, day, "09:00", "17:00")];
    }

    return day;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // not implemented, not needed
  }

  addSegment(day: OpeningHoursDay) {
    if (day.segments.length >= 2) {
      return;
    }
    day.segments.push(new OpeningHoursSegment(this, day, "09:00", "17:00"));
    this.updateDayValidity(day);
  }

  removeSegment(day: OpeningHoursDay, segment: OpeningHoursSegment) {
    if (day.segments.length == 1) {
      day.enabled.setValue(false);
      return;
    }
    const idx = day.segments.indexOf(segment);
    if (idx > -1) {
      day.segments.splice(idx, 1);
    }
    this.updateDayValidity(day);
  }

  updateControlValue() {

    // generate form data opening hours model
    var data = new Array<OpeningHoursItem>();

    this.week.filter(d => d.enabled?.value).forEach(day => {
      day.segments.forEach(s => {
        const item = new OpeningHoursItem();
        item.weekDay = day.weekDay;
        item.fromTime = convertTo24(s.timeFrom.value) ?? "09:00";
        item.tillTime = convertTo24(s.timeTill.value) ?? "17:00";
        data.push(item);
      });
    });

    //console.log("OH, updating ngControl value", data);
    this.onChange(data);
    this.onTouched();

  }

  updateDayValidity(day: OpeningHoursDay) {
    day.segments.forEach(s => {
      s.timeFrom.updateValueAndValidity();
      s.timeFrom.markAsTouched();
      s.timeTill.updateValueAndValidity();
      s.timeTill.markAsTouched();
    });
  }

  defaultWeek(): Array<OpeningHoursDay> {
    return [
      new OpeningHoursDay(this, WeekDayEnum.Monday),
      new OpeningHoursDay(this, WeekDayEnum.Tuesday),
      new OpeningHoursDay(this, WeekDayEnum.Wednesday),
      new OpeningHoursDay(this, WeekDayEnum.Thursday),
      new OpeningHoursDay(this, WeekDayEnum.Friday),
      new OpeningHoursDay(this, WeekDayEnum.Saturday),
      new OpeningHoursDay(this, WeekDayEnum.Sunday),
    ];
  }
}

class OpeningHoursDay {
  public enabled: FormControl = new FormControl(false); // Výchozí hodnota false
  public segments: Array<OpeningHoursSegment>;

  constructor(private source: OpeningHoursComponent, public weekDay: WeekDayEnum) {
    // add segment with empty data
    this.segments = [new OpeningHoursSegment(this.source, this, "", "")];

    this.enabled.valueChanges.subscribe(() => {
      this.source.updateControlValue();
    });
  }
}

class OpeningHoursSegment {

  public timeFrom: FormControl<string | null>;
  public timeTill: FormControl<string | null>;

  constructor(source: OpeningHoursComponent, day: OpeningHoursDay, from: string, till: string) {
    // console.log('OH, new segment', day, from, till);
    if (till == "24:00") till = "23:59";
    if (from.length == 4) from = '0' + from;
    if (till.length == 4) till = '0' + till;

    this.timeFrom = new FormControl<string>(from, [openingHoursDayValidator(day)]);
    this.timeTill = new FormControl<string>(till, [openingHoursDayValidator(day)]);

    this.timeFrom.valueChanges.subscribe(() => source.updateControlValue());
    this.timeTill.valueChanges.subscribe(() => source.updateControlValue());

  }

}

export function openingHoursDayValidator(day: OpeningHoursDay): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    // If the day is not enabled, no validation is needed.
    if (!day.enabled.value) return null;

    let check: ValidationErrors | null = null;

    day.segments.forEach((s) => {

      const from = s.timeFrom.value
        ? parseInt(convertTo24(s.timeFrom.value).replace(':', ''))
        : 0;
      const till = s.timeTill.value
        ? parseInt(convertTo24(s.timeTill.value).replace(':', ''))
        : 0;

      // Check if `fromTime` is greater than or equal to `tillTime`
      if (from >= till && (s.timeFrom === control || s.timeTill === control)) {
        check = {
          openingHoursSegment: {
            message: `Time range invalid: ${s.timeFrom.value} must be earlier than ${s.timeTill.value}.`,
          },
        };
        return;
      }

      // Check for overlapping time ranges within the same day
      day.segments.filter((i) => i !== s).forEach((x) => {
        const from2 = x.timeFrom.value
          ? parseInt(convertTo24(x.timeFrom.value).replace(':', ''))
          : 0;
        const till2 = x.timeTill.value
          ? parseInt(convertTo24(x.timeTill.value).replace(':', ''))
          : 0;

        if (from < till2 && till > from2) {
          if (
            s.timeFrom === control ||
            s.timeTill === control ||
            x.timeFrom === control ||
            x.timeTill === control
          ) {
            check = {
              openingHoursSegment: {
                message: `Overlap detected: ${s.timeFrom.value}-${s.timeTill.value} overlaps with ${x.timeFrom.value}-${x.timeTill.value}.`,
              },
            };
          }
          return;
        }
      });
    });

    // Return validation errors if any issues are found
    return check;
  };
}

const convertTo24 = (time12h: string | null): string => {
  if (!time12h)
    return "";

  const [time, modifier] = time12h.split(' ');

  let [hours, minutes] = time.split(':');

  if (hours === '12' && modifier === 'AM') {
    hours = '00'; // 24 hodin pulnoc
  } else if (hours === '12' && modifier === 'PM') {
    hours = '12'; // 12 hodin odpoledne
  } else if (modifier === 'PM') {
    hours = (parseInt(hours, 10) + 12).toString();
  }

  return `${hours}:${minutes}`;
}

export function openingHoursValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    // console.log("openingHoursValidator", control)

    const items: Array<OpeningHoursItem> = control?.value;
    if (!items)
      return null;

    let check: ValidationErrors | null = null;
    items.forEach(d => {

      const timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/; // Regular expression to validate HH:MM format

        // Check if the time input is valid
        if (!timeRegex.test(d.fromTime) || !timeRegex.test(d.tillTime)) {
          
          check = {
            invalidTime: {
              message: `Invalid time format: "${d.fromTime}-${d.tillTime}". Please enter a valid time in HH:MM format.`
            },
          }
          return;
        }

      const from = (d.fromTime)
        ? parseInt(convertTo24(d.fromTime).replace(":", ""))
        : 0;
      const till = (d.tillTime)
        ? parseInt(convertTo24(d.tillTime).replace(":", ""))
        : 0;

      //console.log("checking", d, from, till, (from >= till));

      if (from >= till) {
        check = { overlapSegment: { weekDay: d.weekDay, fromTime: d.fromTime, tillTime: d.tillTime } }
        return;
      }

      items.filter(x => x != d && x.weekDay == d.weekDay).forEach(x => {

        const from2 = (x.fromTime)
          ? parseInt(convertTo24(x.fromTime).replace(":", ""))
          : 0;
        const till2 = (x.tillTime)
          ? parseInt(convertTo24(x.tillTime).replace(":", ""))
          : 0;

        if (from <= till2 && from >= from2) {
          check = { overlapDay: { weekDay: d.weekDay, fromTime1: d.fromTime, tillTime1: d.tillTime, fromTime2: x.fromTime, tillTime2: x.tillTime } }
          return;
        }
        if (till <= till2 && till >= from2) {
          check = { overlapDay: { weekDay: d.weekDay, fromTime1: d.fromTime, tillTime1: d.tillTime, fromTime2: x.fromTime, tillTime2: x.tillTime } }
          return;
        }
      })
    })

    // result (ok?)
    return check;
  }
}
