<app-sticky-element [isLoginPage]="this.isLoginPage">
    <div class="su-header md:flex md:justify-between">
        <a [routerLink]="'/'" [ariaLabel]="'GoldenPages' | translate"
            class="flex-col lg:hidden md:items-center items-baseline">
            <img src="/assets/images/fcr-logo.png" alt="" class="w-10 h-10 my-2">
        </a>
        <div class="flex items-center">
            <!-- Sidebar toggle -->
            <i class="fa-solid fa-x ml-2 block lg:hidden" *ngIf="!isLoginPage && isHamburgerDisplayed" (click)="isHamburgerDisplayed = !isHamburgerDisplayed"></i>
            <i class="fa-solid fa-bars ml-2 block lg:hidden" *ngIf="!isLoginPage && !isHamburgerDisplayed" (click)="isHamburgerDisplayed = !isHamburgerDisplayed"></i>
            <i class="fa-solid fa-bars ml-2 hidden lg:block" (click)="!isLoginPage && this.sidebar.toggleSidebar()"></i>
        </div>
        
        <!-- Right-side buttons -->
        <div class="gap-4 items-center ml-auto hidden md:flex">
            <!-- Sign Out Button -->
            <a *ngIf="signOutButtonEnabled" (click)="signOut()" class="btn btn--large login-btn">
                <i class="fa-solid fa-lock"></i>
                {{"header.signOut" | translate }}
            </a>

            <!-- Language Switch -->
            <ul [ngClass]="{'hidden': !isLoginPage}" class="md:block">
                <app-language-switch></app-language-switch>
            </ul>
        </div>
    </div>
    <div class="flex flex-col items-center bg-white h-screen gap-4 px-5 justify-start md:justify-between" *ngIf="isHamburgerDisplayed">
        <div class="flex flex-col text-center justify-center w-full" *ngIf="!isLoginPage">
            <a routerLink="/dash" (click)="isHamburgerDisplayed = !isHamburgerDisplayed" routerLinkActive="text-fcr-blue" class="flex items-center py-4 px-6 text-gray-700 hover:bg-gray-100 w-full">
                <i class="fas fa-th-large mr-4 text-xl text-fcr-blue"></i>
                <span class="text-base font-medium text-gray-800">{{ "header.nav.dashboard" | translate }}</span>
            </a>
            <a routerLink="/profile" (click)="isHamburgerDisplayed = !isHamburgerDisplayed" routerLinkActive="text-fcr-blue" class="flex items-center py-4 px-6 text-gray-700 hover:bg-gray-100 w-full">
                <i class="fas fa-building mr-4 text-xl text-fcr-blue"></i>
                <span class="text-base font-medium text-gray-800">{{ "header.nav.profile" | translate }}</span>
            </a>
            <!--
            <a routerLink="/analytics" (click)="isHamburgerDisplayed = !isHamburgerDisplayed" routerLinkActive="text-fcr-blue" class="flex items-center py-4 px-6 text-gray-700 hover:bg-gray-100 w-full">
                <i class="fas fa-chart-line mr-4 text-xl text-fcr-blue"></i>
                <span class="text-base font-medium text-gray-800">{{ "header.nav.statistics" | translate }}</span>
            </a>
            -->
        </div>
        <div class="flex flex-col w-full items-start" [ngClass]="{'my-3': isLoginPage}">
            <p class="text-sm text-gray-500">{{ "header.nav.langSelect" | translate }}</p>
            <div class="w-full">
                <app-language-switch class="block"></app-language-switch>
            </div>
        </div>
        <a *ngIf="signOutButtonEnabled" (click)="signOut()" class="btn btn--large login-btn flex items-center justify-center w-full px-6 py-3 bg-fcr-blue text-white rounded-lg hover:bg-fcr-dark-blue">
            <i class="fa-solid fa-lock mr-3"></i>
            <span class="font-medium">{{"header.signOut" | translate }}</span>
        </a>
    </div>
</app-sticky-element>