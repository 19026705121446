import { Component, ElementRef, EventEmitter, Inject, Input, Optional, Output, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NgControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ParsedPhoneNumber, parsePhoneNumber } from 'awesome-phonenumber';
import { CountriesModalComponent, CountriesModalData } from '../countries-modal/countries-modal.component';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { CountryModel } from '../../../models/country.model';


@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  providers: [{provide: MatFormFieldControl, useExisting: PhoneInputComponent}],
})
export class PhoneInputComponent implements ControlValueAccessor {

 
  @Input()
  label: string = "";  

  @Input()
  primaryCountry: CountryModel | null = null;

  @Input()
  countries: CountryModel[] = [];

  @Input("otherPhoneCtrl")
  otherPhoneCtrl: FormControl<string> | null = null;

  @Input("otherPhone")
  otherPhone: PhoneInputComponent | null = null;

  @Input("showErrors")
  showErrors: boolean = false;

  @Output()
  inputBlur: EventEmitter<boolean> = new EventEmitter<boolean>();

  touched = false;
  onChange = (_: any) => {
    this.countryCntr.setValue(this.phoneCountry);
  };

  onTouched = () => {};

  phoneNumber: ParsedPhoneNumber | null = null;
  phoneCountry: CountryModel | null = null;  
  phoneCntr: FormControl<string | null> = new FormControl(null, [this.phoneNumberValidator(this)]);
  // form: FormGroup;
  countryCntr = new FormControl<CountryModel | null>(null);

  constructor(
    private dialog: MatDialog,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
  ) {

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    //console.log(this.countries)
    this.phoneCountry = this.countries.filter(c => c.code == "BE").shift() ?? null;
    this.countryCntr.setValue(this.phoneCountry);

    this.phoneCntr.valueChanges.subscribe(val => {
      if (!val) {
        // we have to reset phone number
        this.phoneNumber = null;
         // reset country to default
        if (this.primaryCountry)
          this.phoneCountry = this.primaryCountry;

        const prefix = this.phoneCountry?.diallingCode ?? "+";
        this.phoneCntr.setValue(prefix);
      } else {
        // detect and set prefix
        const prefixContry = this.countries.filter(c => val.startsWith(c.diallingCode)).shift();
        if (prefixContry && prefixContry != this.phoneCountry)
            this.phoneCountry = prefixContry;        
      }
      if (this.phoneNumber && this.phoneNumber.valid) {
        // console.log("SET VALUE", this.phoneNumber, this.ngControl, this.ngControl.control);
        this.onChange(this.phoneNumber.number.e164);
        this.onTouched();
      }
      else {
        // console.log('phone, set empty')
        this.onChange(null);
        this.onTouched();
      }
    })

  }

  isFieldValid(): boolean {
    return (!this.phoneCntr || this.phoneCntr?.valid || this.phoneCntr?.untouched); 
  }

  writeValue(val: string | null): void {
    //console.log("phone, writeValue", this.ngControl.name, val)
    if (val) {
      var res = parsePhoneNumber(val);
      if (res && res.valid && res.number.national) {
        this.phoneNumber = res;
        // console.log("UPDATING INTETRNAL TEL. INPUT", res.number.significant)
        this.phoneCntr.setValue(res.number.international ?? "");
      }
      else {
        this.phoneNumber = null;
        this.phoneCntr.setValue(val);
      }
    }
    else {
      this.phoneNumber = null;
      this.phoneCntr.setValue(val);
    }
  }

  getFlagSrc(): string {
    return `https://flagcdn.com/${this.phoneCountry?.code?.toLocaleLowerCase()}.svg`;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    (isDisabled)
      ? this.phoneCntr.disable()
      : this.phoneCntr.enable();
  }


  formatValue() {
    if (this.phoneNumber?.valid && this.phoneCntr.valid) {
      this.phoneCntr.setValue(this.phoneNumber.number.international);
    }
    else {
    }
    this.otherPhone?.phoneCntr?.updateValueAndValidity();
    this.inputBlur.emit(true);
  }

  stripValue() {
    if (this.phoneNumber?.valid && this.phoneCntr.valid)
      this.phoneCntr.setValue(this.phoneNumber.number.e164);
  }

  openCountries() {
    const data: CountriesModalData = { showDialCode: true, showCountryCode: false, countries: this.countries };
    const config: MatDialogConfig = { data: data, width: "500px" };
    const dialogRef = this.dialog.open(CountriesModalComponent, {...config, autoFocus: true});
    dialogRef.afterClosed().subscribe((country: CountryModel) => {
      this.changeCountry(country);
    });
  }

  changeCountry(country: CountryModel | null) {
    if (!country)
      return;

    // console.log("change country", this.phoneCountry);

    if (this.phoneCountry != null) {
      let val = this.phoneCntr.value;
      if (!val || val.length <= this.phoneCountry.diallingCode.length) {
        this.phoneCntr.setValue(country.diallingCode);
      }
      else {
        val = country.diallingCode + val.substring(this.phoneCountry.diallingCode.length);
        this.phoneCntr.setValue(val);
      }
    }
    else {
      this.phoneCntr.setValue(country.diallingCode);
    }
  }

  getError() : string {
    const controlErrors = this.phoneCntr.errors;
    if (controlErrors != null) {
      const error = Object.keys(controlErrors).shift();
      return `fields.${this.ngControl.name}.${error}`
    }
    return "";
  }

  clearValue() {
    this.phoneCntr.setValue(null);
    this.phoneCntr.updateValueAndValidity();
  }

  phoneNumberValidator(phoneInput: PhoneInputComponent): ValidatorFn {
    return (control: AbstractControl) : ValidationErrors | null => {
    
        // console.log("phone validate", phoneInput.phoneCountry, phoneInput.otherPhoneCtrl);
  
        if (!phoneInput.phoneCountry) {
          return { invalidNumber: true };
        }
  
        const value = control.value;
        const otherValue = phoneInput.otherPhoneCtrl?.value;
        // console.log("phone values", this.ngControl.name, value, otherValue);

        if (!value) {
            if (!otherValue) {
              // console.log("return phoneRequired1");
              return { phoneRequired: true };
            }
            else {
              // console.log("return OK1");
              return null;
            }
        }        
        if (value == this.phoneCountry?.diallingCode) {
          if (!otherValue) {
            // console.log("return phoneRequired2");
            return { phoneRequired: true };
          }
          else {
            // console.log("return OK2");
            return null;
          }
        }
  
        const isMobile = phoneInput.ngControl.name?.toString().toLowerCase().indexOf('mobile') != -1;
        const res = parsePhoneNumber(value, {regionCode : phoneInput.phoneCountry.code});
        // console.log("formatNumber", res, checkMobile);
            
        if (res.valid) {
          if (isMobile && res.typeIsMobile != true) {
            phoneInput.phoneNumber = null;
            // console.log("return invalidMobileNumber1");
            return { invalidMobileNumber: true };  
          }
          if (!isMobile && res.typeIsMobile) {
            phoneInput.phoneNumber = null;
            // console.log("return invalidPhoneNumber1");
            return { invalidPhoneNumber: true };  
          }
          phoneInput.phoneNumber = res;
          // console.log("return OK3");
          return null;
        }
        else {
          phoneInput.phoneNumber = null;
          // console.log("return invalidNumber2");
          return { invalidNumber: true };
        }
    }
  }

}

