<app-skeleton *ngIf="this.loading" class="w-100 h-64 lg:h-24 mb-4 rounded-lg"></app-skeleton>
<div *ngIf="!this.loading" class="flex flex-col lg:flex-row justify-between lg:items-center space-y-3 bg-white p-4 rounded-lg shadow-md border border-gray-200 mb-4">
    <!-- Left Side: Title and Address -->
    <div>
        <h3 class="font-medium text-gray-800">{{ listing.organisationName }}</h3>
        <p class="text-gray-600 text-sm">{{ this.listing.addressStreet }} 
            {{ this.listing.addressStreetNumber }}, 
            {{ this.listing.addressPostalCode}}
            {{ this.listing.addressCity }}</p>
    </div>

    <!-- Center Section: Green Circle and Phone Number -->
    <div class="flex items-center lg:justify-center space-x-4">
        <div class="w-10 h-10 rounded-full bg-gray-200 flex items-center justify-center mr-2">
            <app-percentage-gauge [percentage]="this.listing.profileCompletenessPercentage" [showText]="false" class="scale-50"></app-percentage-gauge>
        </div>
        <app-completion-hint [text]="this.listing.profileCompleteness[0].key" [percentage]="this.listing.profileCompleteness[0].weight" [dashboard]="false"></app-completion-hint>
    </div>

    <!-- Right Side: Buttons -->
    <div class="flex items-center space-x-4">
        <!-- Edit Button -->
        <button
            class="flex items-center justify-center bg-primary text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-primary/90"
            style="height: 42px;"
            [routerLink]="['/edit',this.listingId]">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                stroke="currentColor" class="w-5 h-5 mr-2">
                <path stroke-linecap="round" stroke-linejoin="round"
                    d="M15.232 5.232l3.536 3.536M16.732 4.732a2.828 2.828 0 10-4 4L16.732 4.732zM2 20.5a2.828 2.828 0 004 0L2 20.5zm0 0v-4.95a.25.25 0 01.07-.177l11.392-11.39a2.828 2.828 0 014 0 2.828 2.828 0 010 4L6.248 20.43a.25.25 0 01-.177.07h-4.95z" />
            </svg>
            {{ "profile.edit" | translate }}
        </button>

        <!-- View Button -->
        <button
            class="flex items-center justify-center bg-white text-black border border-gray-300 px-4 py-2 rounded-md text-sm font-medium hover:bg-primary hover:text-white hover:border-primary"
            style="height: 42px;"
            (click)="seeOnline('dashboard.viewProfile.link')">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                stroke="currentColor" class="w-5 h-5 mr-2 text-gray-500">
                <path stroke-linecap="round" stroke-linejoin="round"
                    d="M12 4.5c4.28 0 8.168 2.575 9.927 6.687.273.63.273 1.397 0 2.027C20.168 15.925 16.28 18.5 12 18.5S3.832 15.925 2.073 11.213a1.944 1.944 0 010-2.026C3.832 7.075 7.72 4.5 12 4.5zm0 3a3 3 0 100 6 3 3 0 000-6z" />
            </svg>
            {{ "profile.view" | translate }}
        </button>
    </div>
</div>