import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { HPDataModel } from '../models/hp-data.model';
import { Endpoints } from '../constants/endpoints';
import { AuthService } from '@fcr/azure-auth';
import { Router } from '@angular/router';
import { FormDataModel } from '../models/form-data.model';

@Injectable({
    providedIn: 'root',
})
export class DataService {

    constructor(private http: HttpClient, private auth: AuthService, private router: Router) { }

    getHomePageData(): Observable<HPDataModel> {
        return this.http.get<HPDataModel>(Endpoints.GET_LISTING_IDS()).pipe(
            map(data => {
                if (data.count > 0) {
                    data.listings = data.listings.map(listing => ({
                        ...listing,
                        formattedAddress: `${listing.address.street} ${listing.address.housenumber}, ${listing.address.zip} ${listing.address.city}`
                    }));
                    return data
                }
                return {
                    count: 0,
                    listings: []
                } as HPDataModel;
            }),
            catchError(error => {
                if (error.status === 401) {
                    this.auth.localSignOut();
                    this.router.navigateByUrl('/login');
                }
                console.error('Error fetching listings:', error);

                return of({
                    count: 0,
                    listings: []
                } as HPDataModel);
            })
        );
    }

    getListing(id: string): Observable<FormDataModel> {
        return this.http.get<FormDataModel>(Endpoints.LISTING(id)).pipe(
            map(data => {
                data.profileCompleteness = data.profileCompleteness.filter(x => !x.isPassed).sort((a, b) => b.weight - a.weight)
                return data  
            }),
            catchError(error => {
                console.error(`Error fetching listing ${id}`, error);
                return of(new FormDataModel());
            })
        );
    }
}
