<div
  class="flex items-center justify-between px-4 py-2" [ngClass]="isDashboard ? 'rounded-r-full rounded-bl-full shadow-lg mb-2 bg-hint w-fit text-white' : 'text-black'">
  <span class="text-sm lg:font-medium">{{ "tips." + text | translate}}</span>
  <div class="flex items-center bg-green-100 text-green-600 rounded-full px-2 py-1 ml-3">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
      class="w-3 h-3 lg:w-4 lg:h-4 mr-1">
      <path stroke-linecap="round" stroke-linejoin="round" d="M5 12l5-5l5 5" />
    </svg>
    <span class="text-xs lg:text-sm font-semibold">+{{ percentage }}%</span>
  </div>
</div>
