import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { HPDataModel } from '../../models/hp-data.model';
import { LocalStorageService } from '../../services/local-storage.service';
import { DataService } from '../../services/data.service';
import { FormDataModel } from '../../models/form-data.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {
  hpData: HPDataModel = new HPDataModel();
  selectedListing: FormDataModel;
  isDropdownOpen = false;
  showLessTips = true;
  loading = false;

  constructor(
    public translate: TranslateService,
    private title: Title,
    private dataService: DataService,
    private localStorage: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.getListings()
    this.title.setTitle("Myfcrmedia")
  }

  getListings() {
    this.loading = true;
    this.dataService.getHomePageData().subscribe(x => {
      this.hpData = x;
      if(this.hpData.count == 0)
        return

      var selectedListing = this.hpData.listings.find(x => x.id === this.localStorage.favouriteId);
      var selectedListingId = selectedListing ? selectedListing.id : this.hpData.listings[0]?.id;
  
      if (selectedListingId) {
        this.selectListing(selectedListingId);
      }
    });
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  selectListing(id: string) {
    this.isDropdownOpen = false;
    this.localStorage.updateFavourite(id)
    this.loading = true;
    this.dataService.getListing(id).subscribe(x => {
      this.selectedListing = x
      this.loading = false
    });
  }

  seeOnline(url: string) {
    this.translate.get(url).subscribe(x => {
      url = x.replace(':id', this.selectedListing.listingId)
      window.open(url, '_blank');
    })

  }
}
