<app-modal *ngIf="modalMessage" [message]="modalMessage" [route]="modalRoute" [translateMessage]="modalTranslateMessage" (close)="closeModal()"></app-modal>
<main id="main" role="main" class="main">

    <router-outlet></router-outlet>
    <div class="flex">
        <app-sidebar *ngIf="!this.isLoginPage" class="hidden lg:block"></app-sidebar>
        <div class="main-content">
            <app-header [isLoginPage]="isLoginPage"></app-header>
            <div class="lg:m-5">
                <router-outlet />
            </div>
        </div>
    </div>
    <app-footer [isLoginPage]="isLoginPage"></app-footer>
</main>