import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../../../services/data.service';
import { FormDataModel } from '../../../../models/form-data.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-company-card',
  templateUrl: './company-card.component.html',
  styleUrl: './company-card.component.scss'
})
export class CompanyCardComponent implements OnInit{

  @Input("id")
  listingId: string
  listing: FormDataModel

  loading: boolean = false;

  constructor(private data: DataService, public translate: TranslateService){}

  ngOnInit(): void {
    this.loading = true;
    this.data.getListing(this.listingId).subscribe(x => {
      this.listing = x;
      this.loading = false
    })
  }

  seeOnline(url: string) {
    this.translate.get(url).subscribe(x => {
      url = x.replace(':id', this.listingId)
      window.open(url, '_blank');
    })
  }
}
