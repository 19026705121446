import { Location } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  @Input() message: string = ''; // Text to display in the modal
  @Input() route?: string; // Optional route to navigate when closing
  @Input() translateMessage: boolean = false;
  @Output() close = new EventEmitter<void>(); // Event to notify when the modal is closed

  constructor(private router: Router, private location: Location, public translate: TranslateService) {}

  onClose(): void {
    if (this.route && this.route == 'back') {
      this.location.back()
    }
    else if (this.route) {
      this.router.navigate([this.route]);
    }
    this.close.emit();
  }
}