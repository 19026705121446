import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-percentage-gauge',
  templateUrl: './percentage-gauge.component.html',
  styleUrls: ['./percentage-gauge.component.scss']
})
export class PercentageGaugeComponent implements OnChanges {
  @Input() percentage: number = 0;
  @Input() showText: boolean = true;

  strokeColor: string = '#10B981'; // Default to green
  statusText: string = 'level5';


  ngOnChanges(): void {
    this.updateGauge();
  }

  updateGauge(): void {
    if (this.percentage >= 80) {
      this.strokeColor = '#10B981'; // Green
      this.statusText = 'level5';
    } else if (this.percentage >= 60) {
      this.strokeColor = '#3B82F6'; // Blue
      this.statusText = 'level4';
    } else if (this.percentage >= 40) {
      this.strokeColor = '#FBBF24'; // Yellow
      this.statusText = 'level3';
    } else if (this.percentage >= 20) {
      this.strokeColor = '#FB923C'; // Orange
      this.statusText = 'level2';
    } else {
      this.strokeColor = '#EF4444'; // Red
      this.statusText = 'level1';
    }
  }
}
