<div class="flex flex-col items-center">
    <div class="relative">
      <!-- Circular Progress Bar -->
      <svg
        class="w-20 h-20 lg:w-32 lg:h-32"
        viewBox="0 0 36 36"
      >
        <!-- Background Circle -->
        <circle
          cx="18"
          cy="18"
          r="16"
          fill="transparent"
          stroke="rgba(209, 213, 219, 1)"
          stroke-width="4"
        ></circle>
  
        <!-- Dynamic Circle -->
        <circle
          cx="18"
          cy="18"
          r="16"
          fill="transparent"
          [attr.stroke]="strokeColor"
          stroke-width="4"
          stroke-dasharray="100"
          [attr.stroke-dashoffset]="100 - percentage"
          stroke-linecap="round"
          transform="rotate(-90 18 18)"
        ></circle>
      </svg>
  
      <!-- Percentage Text -->
      <div class="absolute inset-0 flex items-center justify-center">
        <p class="text-lg font-bold">
          {{ percentage }}%
        </p>
      </div>
    </div>
  
    <!-- Text Label -->
    <div *ngIf="this.showText"
      class="mt-2 px-4 py-1 rounded-full text-white font-medium"
      [ngClass]="{
        'bg-green-500': percentage >= 80,
        'bg-blue-500': percentage >= 60 && percentage < 80,
        'bg-yellow-500': percentage >= 40 && percentage < 60,
        'bg-orange-500': percentage >= 20 && percentage < 40,
        'bg-red-500': percentage < 20
      }"
    >
      {{ 'dashboard.gauge.' + statusText | translate }}
    </div>
  </div>
  