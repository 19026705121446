<div class="m-2">
  <div class="flex justify-between items-center mb-4">
    <h1 class="text-2xl font-semibold text-gray-800">{{ "profile.title" | translate }}</h1>
    <!--
    <button class="block lg:hidden bg-primary text-white px-4 py-2 rounded shadow hover:bg-primary/90">
      + Add
    </button>
    <button class="hidden lg:block bg-primary text-white px-4 py-2 rounded shadow hover:bg-primary/90">
      + Add new listing
    </button>
    -->
  </div>

  <h2 class="text-lg font-medium text-gray-700 mb-4">{{ "profile.subtitle" | translate }}</h2>

  <div>
    <app-company-card *ngFor="let listing of this.data.listings" [id]="listing.id"></app-company-card>
  </div>

  <div class="flex justify-center space-x-2 mt-8" *ngIf="this.totalPages.length > 1">
    <!-- Previous Button -->
    <button
      class="bg-white text-black border border-black px-4 py-2 rounded hover:bg-primary hover:text-white hover:border-primary disabled:opacity-50 disabled:cursor-not-allowed"
      [disabled]="currentPage === 1" (click)="currentPage > 1 && changePage(currentPage - 1)">
      &lt;
    </button>

    <!-- Page Numbers -->
    <button *ngFor="let page of totalPages" class="px-4 py-2 rounded text-center" [ngClass]="{
        'bg-primary text-white border-none': currentPage === page,
        'bg-white text-black border border-black hover:bg-primary hover:text-white hover:border-primary': currentPage !== page
      }" (click)="changePage(page)">
      {{ page }}
    </button>

    <!-- Next Button -->
    <button
      class="bg-white text-black border border-black px-4 py-2 rounded hover:bg-primary hover:text-white hover:border-primary disabled:opacity-50 disabled:cursor-not-allowed"
      [disabled]="currentPage === totalPages.length"
      (click)="currentPage < totalPages.length && changePage(currentPage + 1)">
      &gt;
    </button>
  </div>
</div>