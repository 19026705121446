import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from "@angular/material/icon";
import { Observable, ReplaySubject, Subject, catchError, debounceTime, map, of, take, takeUntil } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CountriesModalComponent, CountriesModalData } from './countries-modal/countries-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { openingHoursValidator } from './opening-hours/opening-hours.component';
import { Loader } from '@googlemaps/js-api-loader/dist'
import { COUNTRY_UNDEFINED, COUNTRIES } from '../../constants/countries.enum';
import { Endpoints } from '../../constants/endpoints';
import { CountryModel } from '../../models/country.model';
import { FormDataModel, OpeningHoursItem } from '../../models/form-data.model';
import { HeadingModel } from '../../models/heading.model';
import { PlausibleTrackingService } from '../../services/plausible-tracking.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@fcr/azure-auth';
import { Location } from '@angular/common';
import { ModalService } from '../../services/modal.service';


const googleLoader = new Loader({
  apiKey: "AIzaSyBqmblPlYT657Nucv4SnrbtWHouBHcuEZ4",
  version: "weekly",
  libraries: ["places"]
});

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnDestroy {
  showErrors: boolean = false;
  loading: boolean = false;
  headings: HeadingModel[] = [];
  countries: CountryModel[] = [];
  primaryCountry: CountryModel = COUNTRY_UNDEFINED;

  enterpriseNumberVisible: boolean = true;
  kvkNumberVisible: boolean = true;

  enterpriseNumberCtrl: FormControl;
  kvkNumberCtrl: FormControl;

  summaryText: string = "";

  form: FormGroup;
  headingIdCtrl: FormControl = new FormControl(null, [Validators.required]);
  headingCtrl: FormControl = new FormControl(null, [Validators.required]);
  fixedPhoneNumberCtrl: FormControl = new FormControl(null, [phoneRequiredValidator(this)]);
  mobilePhoneNumberCtrl: FormControl = new FormControl(null, [phoneRequiredValidator(this)]);

  addressStreetCtrl: FormControl<string | null> = new FormControl(null, [Validators.required]);
  addressStreetNumberCtrl: FormControl<string | null> = new FormControl(null, [Validators.required]);
  addressStreetDoorCtrl: FormControl<string | null> = new FormControl(null);
  addressCityCtrl: FormControl<string | null> = new FormControl(null, [Validators.required]);
  addressDistrictCtrl: FormControl<string | null> = new FormControl(null);
  addressPostalCodeCtrl: FormControl<string | null> = new FormControl(null, [Validators.required]);
  addressCountryCtrl: FormControl<string | null> = new FormControl(null, [Validators.required]);
  addressCountryNameCtrl: FormControl<string | null> = new FormControl(null);

  formData: FormDataModel = new FormDataModel();

  @ViewChild('headingSelect', { static: true })
  headingSelect: MatSelect | undefined;

  @ViewChild('addressAutocomplete', { static: true })
  addressAutocompleteElement: ElementRef<HTMLInputElement> | undefined;

  @Input("lid")
  listingId: string | null = null;

  @Input("locale")
  currentLocale: string = "be_nl";

  @Input("platform")
  platformName: string = "";
  // .text

  @Input("utm")
  utmTags: Array<any> = [];

  @Input("brand")
  brand: string = "FCR Media";

  @Input("instance")
  instance: string = "BE";

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  // headings
  public headingFilterCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredHeadings: ReplaySubject<HeadingModel[]> = new ReplaySubject<HeadingModel[]>(1);

  // address
  autocompleteService: google.maps.places.AutocompleteService | undefined;
  placesService: google.maps.places.PlacesService | undefined;
  addressSuggestOptions: Array<google.maps.places.AutocompletePrediction> = [];
  addressSearchCtrl: FormControl<string | null> = new FormControl(null)
  addressCountry: CountryModel = COUNTRY_UNDEFINED;
  autocomplete: google.maps.places.Autocomplete | undefined;

  // auth
  submited = false;
  identityId: string | null = null;

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private dialog: MatDialog,
    public translate: TranslateService,
    private http: HttpClient,
    private plausible: PlausibleTrackingService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private router: Router,
    private location: Location,
    private modalService: ModalService

  ) {

    this.matIconRegistry
      .addSvgIcon('close', this.domSanitizer.bypassSecurityTrustResourceUrl(`${Endpoints.SPA_ASSETS()}/icons/close.svg`));
    this.matIconRegistry
      .addSvgIcon('external-link', this.domSanitizer.bypassSecurityTrustResourceUrl(`${Endpoints.SPA_ASSETS()}/icons/external-link.svg`));
    this.matIconRegistry
      .addSvgIcon('add', this.domSanitizer.bypassSecurityTrustResourceUrl(`${Endpoints.SPA_ASSETS()}/icons/add.svg`));

    this.enterpriseNumberCtrl = new FormControl(
      null,
      {
        validators: [Validators.required],
        updateOn: 'submit' // Triggers validation only on submit
      }
    );
    this.kvkNumberCtrl = new FormControl(null, [Validators.required]);

    this.form = new FormGroup({

      // main
      listingId: new FormControl(),
      enterpriseNumber: this.enterpriseNumberCtrl,
      kvkNumber: this.kvkNumberCtrl,
      organisationName: new FormControl(null, [Validators.required]),
      headingId: this.headingIdCtrl,

      // address
      addressSearch: this.addressSearchCtrl,
      addressStreet: this.addressStreetCtrl,
      addressStreetNumber: this.addressStreetNumberCtrl,
      addressStreetDoor: this.addressStreetDoorCtrl,
      addressCity: this.addressCityCtrl,
      addressDistrict: this.addressDistrictCtrl,
      addressPostalCode: this.addressPostalCodeCtrl,
      addressCountry: this.addressCountryCtrl,


      // opening hours
      openingHours: new FormControl<OpeningHoursItem[]>(new Array<OpeningHoursItem>(), [openingHoursValidator()]),
      openingHoursAdditionalInfo: new FormControl(null),

      // contacts
      email: new FormControl(null, [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$')]),
      fixedPhoneNumber: this.fixedPhoneNumberCtrl,
      mobilePhoneNumber: this.mobilePhoneNumberCtrl,
      website: new FormControl(null, [Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]),
      webshop: new FormControl(null, [Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]),
      facebook: new FormControl(null, [Validators.pattern('https:\/\/(www\.)?facebook\.com\/[\\w\\.\\-]+\\/?')]),
      instagram: new FormControl(null, [Validators.pattern('https:\/\/(www\.)?instagram\.com\/[\\w\\.\\-]+\\/?')]),
      linkedIn: new FormControl(null, [Validators.pattern('https:\/\/(www\.)?linkedin\.com\/(in|company)/[\\w\\.\\-]+\\/?')]),
    });

    this.headingIdCtrl.valueChanges.subscribe((val: string) => {
      this.setHeadingById(val);
    })

    this.headingCtrl.valueChanges.subscribe((val: HeadingModel) => {
      this.headingIdCtrl.setValue(val?.headingId, { emitEvent: false });
    })
  }

  getTranslatedText(key: string): string {
    const translatedText = this.translate.instant(key);
    const platformName = this.translate.instant("summary.platformName");
    return translatedText.replaceAll('{0}', platformName);
  }

  setHeadingById(id: any) {
    this.formData.heading = this.headings.filter(h => h.headingId == id)?.shift() ?? null;
    this.headingCtrl.setValue(this.formData.heading);
  }

  isFieldValid(name: string): boolean {
    const fieldCtrl = this.form.get(name);
    return (!fieldCtrl || fieldCtrl?.valid || fieldCtrl?.untouched);
  }

  handleDisplayForm() {
    this.setLocale(this.translate.currentLang);
    this.preloadFormData()
  }

  getInputErrorFor(controlName: string): string {
    const errors = this.getInputErrorsFor(controlName);
    if (!errors || errors.length == 0)
      return "";
    return errors[0];
  }

  getInputErrorsFor(controlName: string): Array<string> {
    const errors = new Array<string>();

    if (!controlName)
      return errors;

    const controlErrors = this.form.get(controlName)?.errors;
    if (!controlErrors)
      return errors;

    // collect errors
    Object.keys(controlErrors).forEach(keyError => {
      errors.push(`askForCodeForm.${controlName}.${keyError}`)
    });

    // default
    return errors;
  }

  getAddressErrorFor(controlName: string): string {
    const errors = this.getAddressErrorsFor(controlName);
    if (!errors || errors.length == 0)
      return "";
    return errors[0];
  }

  getAddressErrorsFor(controlName: string): Array<string> {
    const errors = new Array<string>();

    if (!controlName)
      return errors;

    const controlErrors = this.form.get(controlName)?.errors;
    if (!controlErrors)
      return errors;

    // collect errors
    Object.keys(controlErrors).forEach(keyError => {
      errors.push(`address.${keyError}`)
    });

    // default
    return errors;
  }

  clearInputValue(controlName: string) {
    var ctrl = this.form.get(controlName);
    if (!ctrl) return;
    ctrl.setValue(null);
    ctrl.updateValueAndValidity();
  }

  preloadFormData() {
    this.loading = true;

    const id = this.listingId ?? "";
    const instance = this.instance ?? "BE";

      const url = Endpoints.LISTING(id);
      this.http.get<FormDataModel>(url).subscribe({
        next: (res) => {
          this.formData = res;
          this.form.patchValue(this.formData); 
          if (this.formData.addressCountry) {
            this.setCountry(this.formData.addressCountry);
          }

          this.form.markAllAsTouched();

          this.loading = false;
        },
        error: (err) => {
          if (err.status === 401) {
            this.auth.localSignOut();
            this.router.navigateByUrl('/login');
          }
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
        }
      });
  }

  ngOnInit() {
    this.initGoogleLookup();

    this.route.paramMap
      .subscribe(params => {
        this.listingId = params.get("id");

        this.handleDisplayForm();

        this.translate.onLangChange.subscribe(x => {
          this.setHeadings()
        })
      });
  }

  initHeadingLookup() {
    this.filteredHeadings.next(this.headings.slice());

    this.headingFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterHeadings();
      });
  }

  ngOnChanges() {
    this.setLocale(this.translate.currentLang);

    this.primaryCountry = this.getCountry(this.currentLocale);
  }

  initGoogleLookup() {
    googleLoader.importLibrary("places").then(l => {
      this.autocompleteService = new l.AutocompleteService();
      this.placesService = new l.PlacesService(document.createElement('div'));
      this.addressSearchCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .pipe(debounceTime(300))
        .subscribe((query) => {
          if (query && query.length > 3 && this.autocompleteService) {
            this.getPlacePredictions(query).then(res => {
              if (res) {
                this.addressSuggestOptions = res;
              }
              else {
                this.addressSuggestOptions = [];
              }
            });
          }
        },
          () => { },
          () => { }
        );

    });
  }

  updatePhonesValidation() {
    this.fixedPhoneNumberCtrl.updateValueAndValidity();
    this.mobilePhoneNumberCtrl.updateValueAndValidity();
  }

  fillInAddress() {
    if (!this.autocomplete)
      return;

    var place = this.autocomplete.getPlace();

    this.addressStreetCtrl.setValue(this.getPlacePart(place, "route"));
    this.addressStreetNumberCtrl.setValue(this.getPlacePart(place, "street_number"));
    this.addressStreetDoorCtrl.setValue(this.getPlacePart(place, "premise"));
    this.addressCityCtrl.setValue(this.getPlacePart(place, "locality"));
    this.addressDistrictCtrl.setValue(this.getPlacePart(place, "neighborhood"));
    this.addressPostalCodeCtrl.setValue(this.getPlacePart(place, "postal_code"));
  }

  getPlacePart(place: google.maps.places.PlaceResult, key: string): string | null {
    var res = place.address_components?.filter(x => x.types.indexOf(key) > -1).shift()?.long_name
      ?? null;
    return res;
  }

  getPlacePredictions(query: string): Promise<Array<google.maps.places.AutocompletePrediction> | null> {
    return new Promise((resolve, reject) => {
      this.autocompleteService?.getPlacePredictions({
        types: [],
        input: query,
        componentRestrictions: { country: this.addressCountry.code }
      }, (predictions, status) => {
        if (status == google.maps.places.PlacesServiceStatus.OK) {
          resolve(predictions);
        } else {
          reject(status);
        }
      });
    });
  }

  selectAddressFn = (prediction: google.maps.places.AutocompletePrediction): string => {
    if (!this.placesService || !prediction?.place_id)
      return "";

    const request = {
      placeId: prediction.place_id,
      fields: ["all"],
    };

    let address = "";
    this.placesService.getDetails(request, (place: google.maps.places.PlaceResult | null, status: any) => {
      if (place) {
        this.addressStreetCtrl.setValue(this.getPlacePart(place, "route"));
        this.addressStreetNumberCtrl.setValue(this.getPlacePart(place, "street_number"));
        this.addressStreetDoorCtrl.setValue(this.getPlacePart(place, "premise"));
        this.addressCityCtrl.setValue(this.getPlacePart(place, "locality"));
        this.addressDistrictCtrl.setValue(this.getPlacePart(place, "neighborhood") ?? this.getPlacePart(place, "sublocality_level_1"));
        this.addressPostalCodeCtrl.setValue(this.getPlacePart(place, "postal_code"));
        address = place.formatted_address ?? "";
      }
    });

    return address;
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected setInitialValue() {
    this.filteredHeadings
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        if (this.headingSelect)
          this.headingSelect.compareWith = (a: any, b: any) => a && b && a.headingId === b.headingId;
      });
  }

  protected filterHeadings() {
    if (!this.headings) {
      return;
    }
    // get the search keyword
    let search = this.headingFilterCtrl.value;
    if (!search) {
      this.filteredHeadings.next(this.headings.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter
    this.filteredHeadings.next(
      this.headings.filter(h => h.headingName.toLowerCase().indexOf(search) > -1)
    );
  }

  clearValue(name: string) {
    var ctrl = this.form.get(name);
    if (!ctrl) return;
    ctrl.setValue(null);
    ctrl.updateValueAndValidity();
  }

  openCountries() {
    const data: CountriesModalData = { showDialCode: true, showCountryCode: false, countries: this.countries };
    const config: MatDialogConfig = { data: data, width: "500px", position: { top: "5rem" } };
    const dialogRef = this.dialog.open(CountriesModalComponent, { ...config, autoFocus: true });
    dialogRef.afterClosed().subscribe((country: CountryModel) => {
      if (country) {
        this.addressCountry = country;
        this.form.get("addressCountry")?.setValue(country.name);
        this.setCountry(country.code.toLocaleLowerCase());
      }
    })
  }

  getErrorForOpeningHours(controlName: string): Observable<string> | null | undefined {
    if (!controlName)
      return null;

    const controlErrors = this.form.get(controlName)?.errors;
    if (!controlErrors)
      return null;

    let res: any = null;
    Object.keys(controlErrors).forEach(keyError => {
      const error = controlErrors[keyError];
      res = this.getOpeningHoursError(keyError, error);
    });

    return res;
  }

  getOpeningHoursError(keyError: string, error: any): string {
    if (error['weekDay']) {
      error['dayName'] = this.translate.instant(`weekdays.${error['weekDay']?.toLowerCase()}`);
    }
    return this.translate.instant(`openingHours.${keyError}`, error);
  }

  getErrorFor(controlName: string): string {
    const errors = this.getErrorsFor(controlName);
    if (!errors || errors.length == 0)
      return "";
    const error = errors[0];
    if (error.indexOf("overlap") > -1) {

    }
    return error;
  }

  getErrorsFor(controlName: string): Array<string> {
    const errors = new Array<string>();
  
    if (!controlName) return errors;
  
    const control = this.form.get(controlName);
    if (!control || !control.errors) return errors;
  
    // collect errors
    Object.keys(control.errors || {}).forEach(keyError => {
      errors.push(`fields.${controlName}.${keyError}`);
    });
  
    return errors;
  }

  getAllErrors(): Array<string> {
    const errors = new Array<string>();
    Object.keys(this.form.controls).forEach(key => {
      const controlErrors = this.form.get(key)?.errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          // console.log("getAllErrors", key, keyError);
          if (keyError == "overlapSegment" || keyError == "overlapDay") {
            // Specific error handling for opening hours errors, where aditional values requierd
            const error = controlErrors[keyError];
            errors.push(this.getOpeningHoursError(keyError, error));
          } else {
            errors.push(`fields.${key}.${keyError}`);
          }
        });
      }
    });
    return errors;
  }

  setCountries() {
    const fr = this.currentLocale.slice(-2) == "fr";
    this.countries = COUNTRIES.map(c => ({ name: (fr) ? c.name_fr : c.name_nl ?? "", code: c.code ?? "", diallingCode: c.diallingCode ?? "" }));
  }

  setHeadings() {
    this.http
      .get(Endpoints.FETCH_HEADINGS(this.translate.currentLang.slice(-2).toUpperCase()))
      .subscribe((items: Array<HeadingModel> | any) => {
        this.headings = items;
        this.initHeadingLookup();
        this.setInitialValue();
        this.setHeadingById(this.formData.headingId)
      });
  }

  setCountry(code: string) {
    // console.log('setting country', code, this.addressCountry == COUNTRY_UNDEFINED, this.addressCountry)
    const country = this.getCountry(code);

    if (country == COUNTRY_UNDEFINED)
      return;

    this.addressCountry = this.getCountry(code);
    this.addressCountryNameCtrl.setValue(this.addressCountry.name, { emitEvent: true });
    this.addressCountryCtrl.setValue(this.addressCountry.code, { emitEvent: false });
    this.autocomplete?.setComponentRestrictions({ country: code.toLocaleLowerCase() });
  }

  getCountry(code: string): CountryModel {
    code = code.substring(0, 2).toLocaleLowerCase();

    return this.countries
      .filter(c => c.code.toLocaleLowerCase() == code).shift()
      ?? COUNTRY_UNDEFINED;
  }

  setLocale(localeCode: string) {
    this.currentLocale = localeCode;
    this.setHeadings();
    this.setCountries();
    this.setCountry(localeCode.substring(0, 2));
    if (this.formData)
      this.formData.mainLanguage = localeCode.substring(0, 2);

    this.kvkNumberVisible = false;
    this.kvkNumberCtrl.clearValidators();
    this.kvkNumberCtrl.clearAsyncValidators();
    this.kvkNumberCtrl.updateValueAndValidity();

    this.enterpriseNumberVisible = true;
    this.enterpriseNumberCtrl.addValidators([Validators.required]);
    this.enterpriseNumberCtrl.addAsyncValidators([vatAsyncValidator(this.http)]);
    this.enterpriseNumberCtrl.updateValueAndValidity();

  }

  scrollToTop(elementRef: ElementRef | null): void {
    if (elementRef?.nativeElement) {
      elementRef.nativeElement.scrollIntoView({ block: 'start' });
    }
  }

  cancelEdit() {
    this.location.back()
  }

  postData() {

    if (!this.form.valid) {
      this.showErrors = true;
      return;
    }

    // proceed with submiting the data
    this.showErrors = false;
    var data = this.form.getRawValue();
    data.mainLanguage = this.currentLocale.slice(-2);
    data.utmTags = this.utmTags;
    data.brand = this.brand;
    data.identityId = this.identityId;

    this.formData = data;

    const url = Endpoints.LISTING(this.listingId);

    this.http.post(url, data).subscribe({
      next: (res) => {
        //console.log('POST-OK', res);
        this.loading = false;
        this.formData = data;
        this.submited = true;
        this.modalService.open("modals.formSubmit",true,"back")
        // track
        this.plausible.trackCustomEvent("SubmiEditFormSuccess", { props: { lid: this.listingId } });
      },
      error: err => {
        this.loading = false;
        //console.log('POST-ERROR', err);
        // track
        this.plausible.trackCustomEvent("SubmiEditFormError", { props: { lid: this.listingId } });
      },
      complete: () => {
        //console.log('POST-DONE!');
        this.loading = false;
      }
    });

  }

  toJson(obj: any) {
    return JSON.stringify(obj);
  }

  getHeadingForSummaryPage() {
    let heading = this.headings.find((x: HeadingModel) => { return x.headingId === this.formData.headingId });
    return heading.headingName
  }
}

export function vatAsyncValidator(http: HttpClient): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {

    const err = { wrongVat: true };
    const vat = control.value;
    if (!vat) {
      //console.log("VAT CHECK, no value -> end")
      return of({ required: true })
    }

    if (!/[A-Z]{2}\d+/.test(vat)) {
      //console.log("VAT CHECK, no valid format -> end")
      return of(err);
    }

    const url = Endpoints.VALIDATE_VAT();
    return http
      .post<any>(url, { "vatNumber": `${vat}` })
      .pipe(
        map((res) => {
          //console.log("VAT CHECK, http response", res)
          return (res?.isValid == true)
            ? null
            : err
        }),
        catchError((error) => {
          return of(err)
        })
      );
  }
}

export function phoneRequiredValidator(component: FormComponent): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    const mobileValue = component.mobilePhoneNumberCtrl?.value;
    const fixedValue = component.fixedPhoneNumberCtrl?.value;

    if (mobileValue || fixedValue) {
      return null;
    }

    return { "phoneRequired": true }

  }
}