import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-review-card',
  templateUrl: './review-card.component.html',
  styleUrls: ['./review-card.component.scss'] // Or use inline styles if preferred
})
export class ReviewCardComponent {
  @Input('reviewCount')
  reviewsCount: number = 186; // Example review count
  @Input('averageRating')
  averageRating: number = 3.0; // Example average rating

  getStars(): ('full' | 'half' | 'empty')[] {
    const fullStars = Math.floor(this.averageRating);
    const hasHalfStar = this.averageRating % 1 >= 0.5;
    const totalStars = 5;
  
    return Array.from({ length: totalStars }, (_, index) => {
      if (index < fullStars) {
        return 'full';
      } else if (index === fullStars && hasHalfStar) {
        return 'half';
      } else {
        return 'empty';
      }
    });
  }
  
  
}
